<template>
    <div>
        <b-modal
            :can-cancel="true"
            v-model="isImageModalActive">
            <p
                class="image" >
                <img
                    class="mx-auto"
                    style="max-height: 80vh; width: auto;"
                    :src="require('../assets/no-laboramos-20240504.jpg')"
                    alt="No laboramos"
                >
            </p>
        </b-modal>
        <div class="container mt-5 px-4">
            <div class="columns">
                <div class="column">
                    <h1 class="title is-4 has-text-primary-dark">
                        Nueva transacción
                    </h1>
                </div>
            </div>

            <PendingTransactionsAlert/>

            <div
                class="columns mt-4"
                v-if="isReady">
                <div class="column is-8 is-offset-2">

                    <div class="columns is-mobile">
                        <div class="column">
                            <div class="box">
                                <h2 class="subtitle is-size-6 has-text-primary-dark has-text-centered">
                                    Cuentas en euros
                                </h2>

                                <BField label-position="on-border" label="Cuenta:">
                                    <BSelect v-model="euro"
                                             rounded expanded
                                             placeholder="Seleccione una cuenta..."
                                    >
                                        <option v-for="(item, index) in euroAccounts"
                                                :key="'euro-'+index"
                                                :value="index">
                                            {{ item.bank }} - {{ item.name }} {{ item.lastname }}
                                        </option>
                                    </BSelect>
                                </BField>

                                <div class="boxed-dashed section"
                                     v-if="euro != null"
                                >
                                    <ul>
                                        <li class="mb-0 is-size-7 has-text-primary-dark">
                                            IBAN:
                                        </li>
                                        <li class="mb-3">
                                            {{ euroAccounts[euro].iban }}
                                        </li>

                                        <li class="mb-0 is-size-7 has-text-primary-dark">
                                            Banco:
                                        </li>
                                        <li class="mb-3">
                                            <b>{{ euroAccounts[euro].bank }}</b>
                                        </li>

                                        <li class="mb-0 is-size-7 has-text-primary-dark">
                                            Titular/Beneficiario:
                                        </li>
                                        <li class="mb-3">
                                            {{ euroAccounts[euro].name }} {{ euroAccounts[euro].lastname }}
                                        </li>

                                        <li class="mb-0 is-size-7 has-text-primary-dark">
                                            SWIFT:
                                        </li>
                                        <li class="mb-3">
                                            <span v-if="euroAccounts[euro].swift">{{
                                                euroAccounts[euro].swift
                                            }}</span>
                                            <i v-else class="has-text-grey">No aplica</i>
                                        </li>

                                        <li class="px-3 mb-0 is-size-7 has-text-info-dark has-text-centered mb-4">
                                            <strong class="has-text-info-dark">Concepto obligatorio</strong> de la transferencia:
                                        </li>

                                        <li class="has-text-centered mb-4">
                                            <i>Nombre y apellido o número de DNI/NIE/Pasaporte</i>
                                        </li>

                                        <li class="has-text-centered" style="color:red">
                                            <i>
                                                NO SE ACEPTARÁN CONCEPTOS COMO: REMESA, DEUDA, VENEZUELA, COMIDA, MADRE, MEDICINAS, ETC.
                                            </i>
                                        </li>
                                    </ul>
                                </div>

                                <div v-else
                                     class="has-text-grey is-italic"
                                >
                                    <p class="has-text-centered">
                                        Seleccione una cuenta para visualizar los datos
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="box mb-6 is-relative">
                        <b-loading
                            :is-full-page="false"
                            v-model="upload.loading"
                        >
                        </b-loading>

                        <div class="columns is-mobile">
                            <div class="column">
                                <b-steps
                                    rounded
                                    animated
                                    :has-navigation="step !== 4"
                                    type="is-primary"
                                    size="is-small"
                                    v-model="step">
                                    <template
                                        #navigation="{next, previous}"
                                        v-if="step !== 4">
                                        <div class="columns is-mobile px-3">
                                            <div class="column is-6">
                                                <b-button
                                                    rounded
                                                    label="Retroceder"
                                                    icon-left="angle-left"
                                                    type="is-link"
                                                    :disabled="previous.disabled"
                                                    @click.prevent="previous.action"
                                                >
                                                </b-button>
                                            </div>
                                            <div class="column is-6 has-text-right">
                                                <b-button rounded
                                                          label="Continuar"
                                                          icon-right="angle-right"
                                                          type="is-link"
                                                          :disabled="next.disabled"
                                                          @click.prevent="next.action"
                                                >
                                                </b-button>
                                            </div>

                                        </div>

                                    </template>

                                    <b-step-item label="Tipo de transacción"
                                                 step="1">

                                        <div class="line-divider my-0 mb-5"></div>

                                        <h2 class="subtitle has-text-info-dark has-text-centered">
                                            Tipo de transacción
                                            <b-tooltip
                                                label="El tipo de transacción es, la forma en que recibirás tu dinero, puede el beneficiario retirarlo en dólares en efectivo, en Caracas o Maracaibo o recibirlo como una transferencia en bolívares a su cuenta bancaria"
                                                type="is-dark"
                                                position="is-bottom"
                                                multilined>
                                                <b-tag rounded
                                                       size="is-small"
                                                       type="is-info"
                                                       style="cursor: pointer"
                                                       role="button">
                                                    <b-icon icon="question"
                                                            size="is-small"
                                                    >
                                                    </b-icon>
                                                </b-tag>
                                            </b-tooltip>
                                        </h2>

                                        <div class="columns my-4 is-align-items-center">

                                            <div class="column">
                                                <div class="boxed-outline px-4 py-5"
                                                     role="radio"
                                                     :class="{'boxed-outline-active': isBank}"
                                                     @click="type = 'bank'">
                                                    <p class="has-text-centered mb-2">
                                                        <b-icon icon="piggy-bank"
                                                                size="is-large"
                                                        >
                                                        </b-icon>
                                                    </p>
                                                    <p class="has-text-centered mb-0">
                                                        Transferencia bancaria en bolívares
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="column">

                                                <div
                                                    class="boxed-outline px-4 py-6"
                                                    role="radio"
                                                    :class="{'boxed-outline-active': isCash}"
                                                    @click="type = 'cash'">
                                                    <p class="has-text-centered mb-2">
                                                        <b-icon icon="money-bill-wave"
                                                                size="is-large"
                                                        >
                                                        </b-icon>
                                                    </p>
                                                    <p class="has-text-centered mb-0">
                                                        Retiro de dólares en efectivo (Caracas o Maracaibo)
                                                    </p>
                                                </div>
                                            </div>

                                        </div>

                                        <p class="has-text-centered px-5">
                                            Por favor, elige el tipo de transacción
                                        </p>

                                    </b-step-item>

                                    <b-step-item label="Monto"
                                                 step="2">

                                        <div class="line-divider mt-0 mb-5"></div>

                                        <h2 class="subtitle has-text-info-dark has-text-centered">
                                            Monto e Identificación
                                            <b-tooltip
                                                label="Introduce el monto de la transacción en Euros y el número del documento de identidad del titular de la cuenta en Euros"
                                                type="is-dark"
                                                position="is-bottom"
                                                multilined>
                                                <b-tag rounded
                                                       size="is-small"
                                                       type="is-info"
                                                       style="cursor: pointer"
                                                       role="button">
                                                    <b-icon icon="question"
                                                            size="is-small"
                                                    >
                                                    </b-icon>
                                                </b-tag>
                                            </b-tooltip>
                                        </h2>

                                        <div class="mx-auto mt-6 mb-3"
                                             style="max-width: 380px">

                                            <b-field label="Monto"
                                                     style="margin-bottom: 0 !important"
                                                     label-position="on-border">

                                                <b-numberinput rounded
                                                               controls-rounded
                                                               controls-alignment="right"
                                                               step="0.10"
                                                               v-model="amount"
                                                               :type="messages.amount ? 'is-danger' : 'is-primary'"
                                                               @input="checkAmount"
                                                >
                                                </b-numberinput>

                                            </b-field>
                                            <p class="mb-5 mt-0 is-size-7 has-text-danger"
                                               v-if="messages.amount">
                                                {{ messages.amount }}
                                            </p>

                                            <b-field label="Concepto"
                                                     label-position="on-border"
                                                     class="mt-5">

                                                <b-tooltip type="is-dark"
                                                           position="is-top"
                                                           :triggers="['focus', 'click']"
                                                           :auto-close="['outside', 'escape']"
                                                           style="width: 100%">
                                                    <template #content>
                                                        <p class="mb-0">
                                                            Concepto obligatorio número de identificación
                                                        </p>
                                                    </template>

                                                    <b-input type="text"
                                                             rounded
                                                             placeholder="Ej. DNI 99999999X"
                                                             v-model="concept"
                                                             required
                                                    >
                                                    </b-input>
                                                </b-tooltip>

                                            </b-field>

                                            <p class="has-text-centered my-6">
                                                <b-tag type="is-primary"
                                                       size="is-large"
                                                       v-if="type === 'bank'">
                                                    {{ Bs.toLocaleString('es-ES') + ' Bs' }}
                                                </b-tag>
                                                <b-tag type="is-primary"
                                                       size="is-large"
                                                       v-else-if="type === 'cash'">
                                                    {{ Bs.toLocaleString('es-ES') }} $
                                                </b-tag>
                                            </p>

                                        </div>

                                        <b-message type="is-danger"
                                                   size="is-small"
                                                   class="mb-4">
                                            La persona registrada en este perfil, debe ser de forma OBLIGATORIA el titular
                                            de la cuenta en Euros desde donde transfiere.<br>(no se aceptaran pagos de
                                            terceros, es decir, NO se aceptan pagos de familiares, amigos, etc)
                                        </b-message>

                                    </b-step-item>

                                    <b-step-item label="Beneficiario"
                                                 step="3">

                                        <div class="line-divider mt-0 mb-5"></div>

                                        <h2 class="subtitle has-text-info-dark has-text-centered">
                                            {{ type === 'bank' ? 'Cuenta del beneficiario' : 'Datos del beneficiario' }}
                                            <b-tooltip
                                                label="Ahora selecciona una o más de tus cuentas en bolívares para recibir el pago, puedes dividir el mismo añadiendo varias cuentas en la transacción"
                                                type="is-dark"
                                                position="is-bottom"
                                                multilined
                                                v-if="type === 'bank'">
                                                <b-tag rounded
                                                       size="is-small"
                                                       type="is-info"
                                                       style="cursor: pointer"
                                                       role="button">
                                                    <b-icon icon="question"
                                                            size="is-small"
                                                    >
                                                    </b-icon>
                                                </b-tag>
                                            </b-tooltip>
                                            <b-tooltip
                                                label="Añade un beneficiario, el cual retirará los dolares en la ciudad de Caracas o Maracaibo usando el número de referencia generado para esta transacción"
                                                type="is-dark"
                                                position="is-bottom"
                                                multilined
                                                v-else>
                                                <b-tag rounded
                                                       size="is-small"
                                                       type="is-info"
                                                       style="cursor: pointer"
                                                       role="button">
                                                    <b-icon icon="question"
                                                            size="is-small"
                                                    >
                                                    </b-icon>
                                                </b-tag>
                                            </b-tooltip>
                                        </h2>

                                        <div class="mx-auto"
                                             style="max-width: 400px">
                                            <SeleccionarCuentaBancaria
                                                v-if="type === 'bank'"
                                                :accounts.sync="accounts"
                                                :amount="amount"
                                                :modal-number="modalNumber"
                                                :modal-select="modalSelect"
                                                :user-accounts="userAccounts"
                                            />

                                            <div
                                                class="my-6"
                                                v-if="accountsUnavailable && type === 'bank'">
                                                <p class="has-text-grey has-text-centered px-6 mb-5">
                                                    <i>No tienes cuentas bancarias registradas, por favor registra tu
                                                        primera cuenta para empezar a enviar</i>
                                                </p>
                                                <p class="has-text-centered">
                                                    <b-button label="Agregar cuenta"
                                                              icon-right="plus"
                                                              type="is-link"
                                                              rounded
                                                              size="is-small"
                                                              @click="$router.push({ name: 'Accounts', query: { modal: 'open', redirect: path } })"
                                                    >
                                                    </b-button>
                                                </p>
                                            </div>

                                            <div class="is-flex is-justify-content-center mt-4"
                                                 v-else-if="type === 'cash'">
                                                <b-button type="is-success"
                                                          icon-right="plus"
                                                          label="Añadir beneficiario"
                                                          rounded
                                                          size="is-small"
                                                          @click="modalCash = true"
                                                >
                                                </b-button>
                                            </div>

                                            <div v-if="accounts.length > 0 && false"
                                                 class="mt-4">
                                                <div
                                                    class="boxed-dashed px-3 py-4 mb-2"
                                                    v-for="(item, index) in accounts"
                                                    :key="'1sel-account-'+item.id">
                                                    <p class="mb-0 has-text-right px-4">
                                                        <b-button
                                                            type="is-danger"
                                                            @click="accounts.splice(index, 1)"
                                                            size="is-small"
                                                            rounded
                                                            icon-left="trash"
                                                        />
                                                    </p>
                                                    <div class="columns is-mobile">
                                                        <div class="column is-9">
                                                            <ul>
                                                                <li class="mb-2 has-text-primary">
                                                                    {{ ((userAccounts[item.i] || {}).bank || {}).name }}
                                                                </li>
                                                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                                                    Código:
                                                                </li>
                                                                <li class="mb-1">
                                                                    {{ toCodeString((userAccounts[item.i] || {}).code) }}
                                                                </li>
                                                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                                                    Beneficiario:
                                                                </li>
                                                                <li class="mb-1">
                                                                    {{ (userAccounts[item.i] || {}).beneficiary }}
                                                                </li>
                                                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                                                    Cédula/RIF:
                                                                </li>
                                                                <li class="mb-1">
                                                                    {{ (userAccounts[item.i] || {}).dni }}
                                                                </li>
                                                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                                                    Tipo de cuenta:
                                                                </li>
                                                                <li class="mb-1">
                                                                    {{ ((userAccounts[item.i] || {}).type || {}).name }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div
                                                            class="column is-3 is-flex py-4 is-justify-content-flex-end is-flex-direction-column">
                                                            <p class="mb-1 is-size-4 has-text-centered">
                                                                <b>{{ item.amount }} €</b>
                                                            </p>
                                                            <p class="mb-0 is-size-7">
                                                                <b>{{ (item.amount * rate.rate).toLocaleString('es-ES') }}
                                                                    Bs</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <p class="mb-2 mt-3 has-text-danger is-size-7 has-text-centered"
                                                   v-if="! amountsValidate">
                                                    Los montos a enviar en las cuentas, no coinciden con el monto total de
                                                    la transacción
                                                </p>
                                            </div>

                                            <div v-else-if="type === 'cash' && dataCash.name !== null && dataCash.name !== undefined && dataCash.dni !== null && dataCash.dni !== undefined"
                                                 class="mt-4">
                                                <div class="boxed-dashed px-3 py-4">
                                                    <ul>
                                                        <li class="mb-0 is-size-7 has-text-primary-dark">
                                                            Nombre y apellidos:
                                                        </li>
                                                        <li class="mb-3">
                                                            {{ dataCash.name }}
                                                        </li>

                                                        <li class="mb-0 is-size-7 has-text-primary-dark">
                                                            Cédula/RIF:
                                                        </li>
                                                        <li class="mb-3">
                                                            {{ dataCash.dni }}
                                                        </li>

                                                        <li class="mb-0 is-size-7 has-text-primary-dark">
                                                            Teléfono:
                                                        </li>
                                                        <li class="mb-3">
                                                            {{ dataCash.phone }}
                                                        </li>

                                                        <li class="mb-0 is-size-7 has-text-primary-dark">
                                                            Ciudad de retiro:
                                                        </li>
                                                        <li class="mb-0">
                                                            {{ dataCash.city }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>

                                    </b-step-item>

                                    <b-step-item label="Cuenta de pago"
                                                 step="4">

                                        <div class="line-divider mt-0 mb-5"></div>

                                        <h2 class="subtitle has-text-info-dark has-text-centered">
                                            Cuenta de pago
                                            <b-tooltip
                                                label="Aquí seleccionas la cuenta Europea a donde realizaras el pago de los Euros"
                                                type="is-dark"
                                                position="is-bottom"
                                                multilined>
                                                <b-tag rounded
                                                       size="is-small"
                                                       type="is-info"
                                                       style="cursor: pointer"
                                                       role="button">
                                                    <b-icon icon="question"
                                                            size="is-small"
                                                    >
                                                    </b-icon>
                                                </b-tag>
                                            </b-tooltip>
                                        </h2>
                                        <div
                                            v-if="false"
                                            style="text-align: center;"
                                        >
                                            <img
                                                :src="require('../assets/pago_con_tarjeta.jpeg')"
                                                style="max-width:370px;"
                                                alt="pago con tarjeta"
                                                height="180"
                                            >
                                        </div>

                                        <div class="mx-auto mt-4"
                                             style="max-width: 380px">

                                            <b-field label="Cuenta europea"
                                                     label-position="on-border">
                                                <b-select expanded
                                                          rounded
                                                          v-model="euro"
                                                          placeholder="Selecciona una cuenta"
                                                          @input="showMessage">
                                                    <option v-for="(item, index) in euroAccounts"
                                                            :key="'euro-'+index"
                                                            :value="index">
                                                        {{ item.bank }} - {{ item.name }} {{ item.lastname }}
                                                    </option>

                                                </b-select>
                                            </b-field>
                                            <div
                                                v-if="mustUploadFile"
                                            >

                                                <b-field

                                                    class="file is-primary mt-5 is-flex is-justify-content-center"
                                                         :class="{'has-name': !!capture}"
                                                         style="margin-bottom: 0 !important">

                                                    <b-upload accept="image/*,.pdf"
                                                              rounded
                                                              v-model="capture"
                                                              class="file-label"
                                                              @input="setPreview">
                                                        <span class="file-cta">
                                                            <b-icon icon="image"></b-icon>
                                                            <span class="file-label">{{
                                                                capture !== null ? (capture.name.length > 20 ? capture.name.substring(0, 19) + '...' : capture.name) : 'Selecionar imagen'
                                                            }}</span>
                                                        </span>
                                                    </b-upload>

                                                </b-field>

                                                <p class="px-3 mt-2 is-size-7 has-text-info-dark has-text-centered">Sube el
                                                    comprobante de tu pago donde se identifique nombre del titular y monto, <i>por
                                                        ejemplo: una captura de pantalla</i></p>

                                                <div class="boxed-dashed section mb-4"
                                                 v-if="euro != null">
                                                <ul>
                                                    <li class="mb-0 is-size-7 has-text-primary-dark">
                                                        IBAN:
                                                    </li>
                                                    <li class="mb-3">
                                                        {{ euroAccounts[euro].iban }}
                                                    </li>

                                                    <li class="mb-0 is-size-7 has-text-primary-dark">
                                                        Banco:
                                                    </li>
                                                    <li class="mb-3">
                                                        <b>{{ euroAccounts[euro].bank }}</b>
                                                    </li>

                                                    <li class="mb-0 is-size-7 has-text-primary-dark">
                                                        Titular/Beneficiario:
                                                    </li>
                                                    <li class="mb-3">
                                                        {{ euroAccounts[euro].name }} {{ euroAccounts[euro].lastname }}
                                                    </li>

                                                    <li class="mb-0 is-size-7 has-text-primary-dark">
                                                        SWIFT:
                                                    </li>
                                                    <li class="mb-3">
                                                        <span v-if="euroAccounts[euro].swift">
                                                            {{ euroAccounts[euro].swift  }}
                                                        </span>
                                                        <i v-else class="has-text-grey">No aplica</i>
                                                    </li>

                                                    <li class="px-3 mb-0 is-size-7 has-text-info-dark has-text-centered mb-4">
                                                        <strong class="has-text-info-dark">Concepto obligatorio</strong> de la transferencia:
                                                    </li>

                                                    <li class="has-text-centered mb-4">
                                                        <i>Nombre y apellido o número de DNI/NIE/Pasaporte</i>
                                                    </li>

                                                    <li class="has-text-centered" style="color:red">
                                                        <i>
                                                            NO SE ACEPTARÁN CONCEPTOS COMO: REMESA, DEUDA, VENEZUELA, COMIDA, MADRE, MEDICINAS, ETC.
                                                        </i>
                                                    </li>
                                                </ul>
                                            </div>

                                            </div>
                                            <b-message
                                                v-else
                                                type="is-info"
                                                closable
                                                size="is-small"
                                            >
                                                <template #header>
                                                    <p class="mb-0">
                                                        Pasarela de pago
                                                    </p>
                                                </template>
                                                Ud será redirigido a nuestra pasarela de pagos para continuar con el proceso
                                            </b-message>
                                            <b-message type="is-info"
                                                       closable
                                                       size="is-small">
                                                <template #header>
                                                    <p class="mb-0">¡Importante!</p>
                                                </template>
                                                Estimado usuario, el pago en euros debe ser realizada desde una
                                                cuenta a su nombre de forma OBLIGATORIA (no se aceptarán pagos de terceros)
                                            </b-message>

                                            <b-message
                                                v-if="mustUploadFile && type === 'bank'"
                                                type="is-info"
                                                closable
                                                size="is-small">
                                                <template #header>
                                                    <p class="mb-0">¡Importante!</p>
                                                </template>
                                                Debes realizar la transferencia inmediata o de lo contrario el pago en
                                                bolívares se realizara cuando el ingreso este disponible en nuestra cuenta
                                                1-2 días hábiles
                                            </b-message>

                                            <b-message type="is-info"
                                                       closable
                                                       size="is-small"
                                                       v-if="type === 'cash'">
                                                <template #header>
                                                    <p class="mb-0">¡Importante!</p>
                                                </template>
                                                Debes realizar la transferencia inmediata o de lo contrario el pago en
                                                dólares se realizara cuando el ingreso este disponible en nuestra cuenta 1-2
                                                días hábiles
                                            </b-message>

                                        </div>

                                    </b-step-item>

                                    <b-step-item label="Enviar"
                                                 icon="check"
                                                 type="is-success">

                                        <div class="line-divider mt-0 mb-5"></div>

                                        <h2
                                            @click="updateUserWithpersonaSuccess"
                                            class="subtitle has-text-info-dark has-text-centered">
                                            Enviar transacción
                                        </h2>
                                        <div
                                            class="text-center"
                                            v-if="mustWithpersonaIdentify">
                                            <Identified
                                                @success="updateUserWithpersonaSuccess"
                                            />
                                        </div>
                                        <p class="has-text-centered px-3"
                                           v-if="validateErrors.length < 1">
                                            Por favor, verifica que todo este correcto y de ser así, envia tu transacción.
                                        </p>

                                        <p class="has-text-centered px-3"
                                           v-else>
                                            Por favor, corrige los siguientes errores:
                                        </p>

                                        <ul class="has-text-centered is-size-7 mb-6 px-6">

                                            <li class="mb-3"
                                                v-for="(error, index) in validateErrors"
                                                :key="`error-${error.message}`">
                                                <a href="#" class="has-text-danger"
                                                   @click.prevent="step = error.step"
                                                   style="text-decoration: underline; text-transform: uppercase">
                                                    <b>{{ (index + 1) }}.</b> {{ error.message }}
                                                </a>
                                            </li>

                                        </ul>

                                        <p class="subtitle has-text-centered mb-2">
                                            <b>Monto: </b> {{ amount }} €
                                        </p>

                                        <p class="subtitle has-text-centered">
                                            <b>A recibir: </b>
                                            {{ type === 'bank' ? (Bs).toLocaleString('es-ES') + ' Bs' : Bs + ' $' }}
                                        </p>

                                        <div class="mx-auto my-5"
                                             style="max-width: 320px">

                                            <b-image :src="preview"
                                                     v-if="preview !== null && preview !== undefined && typeCapture === 'image'"
                                            >
                                            </b-image>

                                            <pdf v-else-if="typeCapture === 'pdf'"
                                                 :src="preview"
                                            >
                                            </pdf>

                                        </div>

                                        <div class="columns is-mobile px-3 is-flex-wrap-wrap"
                                             v-if="type === 'bank'">
                                            <div class="column is-6">
                                                <b-button label="Retroceder"
                                                          icon-left="angle-left"
                                                          type="is-link"
                                                          rounded
                                                          @click="step--"
                                                >
                                                </b-button>
                                            </div>

                                            <div class="column is-6 has-text-right">
                                                <b-button label="Enviar"
                                                          rounded
                                                          type="is-success"
                                                          icon-right="check"
                                                          :disabled="!isSendBank"
                                                          @click="sendBank"
                                                >
                                                </b-button>
                                            </div>

                                            <div class="column is-12">
                                                <b-progress :rounded="false"
                                                            :value="upload.value"
                                                            type="is-success"
                                                            size="is-small"
                                                            v-if="upload.loading"
                                                >
                                                </b-progress>
                                            </div>

                                        </div>

                                        <div class="columns is-mobile px-3"
                                             v-else-if="type === 'cash'">
                                            <div class="column is-6">
                                                <b-button label="Retroceder"
                                                          icon-left="angle-left"
                                                          type="is-link"
                                                          rounded
                                                          @click="step--"
                                                >
                                                </b-button>
                                            </div>

                                            <div class="column is-6 has-text-right">
                                                <b-button label="Enviar"
                                                          rounded
                                                          type="is-success"
                                                          icon-left="arrow-right"
                                                          :disabled="!isSendCash"
                                                          @click="sendCash"
                                                >
                                                </b-button>
                                            </div>
                                        </div>

                                    </b-step-item>

                                </b-steps>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div class="columns"
                 v-else>
                <div class="column is-8 is-offset-2">
                    <div class="box mt-4">
                        <div class="mb-6">
                            <div class="columns is-mobile is-flex-wrap-wrap">
                                <div class="column is-12 mb-4">
                                    <b-skeleton animated
                                                width="80%"
                                                position="is-centered"
                                                height="2rem"
                                    >
                                    </b-skeleton>
                                </div>
                                <div class="column is-12">
                                    <b-skeleton animated
                                                width="60%"
                                                position="is-centered"
                                                height="1rem"
                                    >
                                    </b-skeleton>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <b-skeleton animated
                                                height="320px"
                                                width="90%"
                                                position="is-centered"
                                    >
                                    </b-skeleton>
                                </div>

                                <div class="column">
                                    <b-skeleton animated
                                                height="320px"
                                                width="90%"
                                                position="is-centered"
                                    >
                                    </b-skeleton>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <b-modal scroll="clip"
                     v-model="modal"
                     :width="440"
                     v-if="isReady && !accountsUnavailable">


            </b-modal>

            <b-modal v-model="modalCash"
                     scroll="keep"
                     :width="440">

                <div class="box mb-0">

                    <h3 class="subtitle has-text-primary-dark">
                        Datos de retiro
                    </h3>

                    <b-message type="is-info"
                               has-icon>

                        <template #header>
                            <span>Retiro en efectivo</span>
                        </template>

                        El beneficiario podrá retirar el efectivo, con el código de referencia de la transacción,
                        y los datos ingresados aquí. En las ciudad de <b>Caracas</b> o <b>Maracaibo</b>

                    </b-message>

                    <b-field label="Nombre y apellidos:"
                             label-position="on-border">

                        <b-input type="text"
                                 rounded
                                 v-model="dataCash.name"
                                 placeholder="Pedro Perez"
                        >
                        </b-input>

                    </b-field>

                    <b-field label="Cédula/RIF:"
                             label-position="on-border">

                        <b-input type="text"
                                 rounded
                                 v-model="dataCash.dni"
                                 placeholder="V9999999"
                        >
                        </b-input>

                    </b-field>

                    <number-input v-model="dataCash.phone"
                                  :countries="world"
                                  default-country="VE"
                    />

                    <b-field label="Ciudad"
                             label-position="on-border"
                             style="z-index: 3">
                        <b-select v-model="dataCash.city"
                                  placeholder="Ciudad de retiro"
                                  expanded
                                  rounded>

                            <option v-for="item in cities"
                                    :key="`city-${item}`"
                                    :value="item">
                                {{ item }}
                            </option>

                        </b-select>
                    </b-field>

                    <b-field class="is-flex is-justify-content-end">
                        <b-button
                            rounded
                            icon-right="check"
                            type="is-success"
                            label="Aceptar"
                            @click="modalCash = false">
                        </b-button>
                    </b-field>

                </div>

            </b-modal>
        </div>
    </div>
</template>

<script>
import pdf from 'vue-pdf'
import SeleccionarCuentaBancaria from "@/views/NewTransaction/SeleccionarCuentaBancaria"
import Identified from "@/views/Identify/Identified"
import PendingTransactionsAlert from "@/views/NewTransaction/PendingTransactionsAlert"
import NumberInput from '../components/NumberInput.vue'

export default {
    watch: {
        modal() {

            this.$parent.$parent.navbarZ();

        },

        modalCash() {

            this.$parent.$parent.navbarZ();

        },

        type(value) {
            value === 'cash' ? this.amount = 100.00 : this.amount = 20.00
        }

    },
    data() {
        return {
            isImageModalActive: false,
            euroAccounts: [],
            userAccounts: [],
            rate: {},
            eurToUsdRate: 1,
            modal: false,
            modalSelect: 0,
            modalNumber: 20.00,
            modalCash: false,
            dataCash: {
                name: null,
                dni: null,
                city: null,
                phone: null,
                translation: {
                    countrySelectorLabel: 'Código',
                    countrySelectorError: 'Selecciona un país',
                    phoneNumberLabel: 'Número de teléfono',
                    example: 'Ejemplo :'
                }
            },

            step: 0,
            accountsUnavailable: false,
            preview: null,

            amount: 20.00,
            concept: null,
            type: 'bank',
            accounts: [],
            euro: null,
            capture: null,

            upload: {
                loading: false,
                value: 0
            },

            messages: {
                amount: false
            },

            cities: [
                'Caracas', 'Maracaibo'
            ],

            world: null
        }
    },

    computed: {
        userVerifications() {
            return this.$root.user.user_verifications || [];
        },
        withpersanaVerified() {
            return this.userVerifications.some((v) => {
                return v.provider === 'withpersona';
            })
        },
        mustWithpersonaIdentify() {
            return this.usesExternalGateway && !this.withpersanaVerified;
        },
        usesExternalGateway() {
            return this.selectedEuroAccount.uses_external_gateway;
        },
        selectedEuroAccount() {
            return this.euroAccounts[this.euro] || {}   ;
        },
        mustUploadFile() {
            return !this.usesExternalGateway;
        },
        amountsValidate() {
            let e = 0;
            this.accounts.forEach(item => {
                e += item.amount
            });
            return e === this.amount;
        },
        isReady() {
            return Object.keys(this.rate).length > 0 && this.euroAccounts.length > 0 && (this.userAccounts.length > 0 || this.accountsUnavailable)
        },
        isSendBank() {
            if (this.amount < 20.0) return false;
            if(!this.accounts.length) return false;
            if (!this.selectedEuroAccount.id) return false;
            if (!this.amountsValidate) return false;
            if (!this.concept) return false;
            if (this.amount > 1000.0) return false;

            return !(this.mustUploadFile && !this.capture);
        },
        isSendCash() {
            if (this.amount < 100.0) return false;
            if (!this.euro) return false;
            if(!this.dataCash.name) return false;
            if (!this.dataCash.dni) return false;
            if (!this.dataCash.city) return false;
            if (!this.dataCash.phone) return false;
            if (!this.concept) return false;
            if (this.amount > 1000.0) return false;

            return !(this.mustUploadFile && !this.capture);
        },
        isCash() {
            return this.type === 'cash'
        },
        isBank() {
            return this.type === 'bank'
        },
        Bs() {
            if (isNaN(this.amount)) {
                return 'Valor no valido';
            }
            if (!this.isCash) {
                return this.amount * this.rate.rate;
            }
            return this.amount * this.eurToUsdRate.rate;
        },
        typeCapture() {
            if (this.capture instanceof File) {
                return /^(image\/).*/.test(this.capture.type) ? 'image' :
                    this.capture.type === 'application/pdf' ? 'pdf' : false
            } else {
                return false
            }
        },
        validateErrors() {

            let errors = []
            //max amount 1000
            if (this.amount > 1000.0) {
                errors.push({
                    message: 'El monto máximo de envío es de 1000€. Si deseas enviar más, por favor contactatanos al +34603176256 por whatsapp',
                    step: 1
                })
            }

            if (this.isCash) {
                if (!this.amount >= 100.0) {
                    errors.push({
                        message: 'El monto mínimo de envío es de 100€',
                        step: 1
                    })
                }
                if (this.dataCash.name == null || this.dataCash.dni == null || this.dataCash.city == null || this.dataCash.phone == null || this.dataCash.name === '' || this.dataCash.dni === '' || this.dataCash.city === '' || this.dataCash.phone === '') {
                    errors.push({
                        message: 'Complete todos los datos del beneficiario',
                        step: 2
                    })
                }
            } else if (this.isBank) {
                if (!this.amount >= 20.0) {
                    errors.push({
                        message: 'El monto mínimo de envío es de 20€',
                        step: 1
                    })
                }

                if (!this.accounts.length > 0) {
                    errors.push({
                        message: 'Debe añadir al menos un beneficiario',
                        step: 2
                    })
                }

                if (!this.amountsValidate) {
                    errors.push({
                        message: 'El monto total a enviar en los beneficiarios, no coincide con el monto de la transacción',
                        step: 2
                    })
                }
            }

            if (this.concept === null || this.concept === '') {
                errors.push({
                    message: 'Especifique el concepto',
                    step: 1
                })
            }

            if (this.euro == null) {
                errors.push({
                    message: 'Seleccione una cuenta de pago en Euros',
                    step: 3
                })
            }

            if (this.capture == null && this.mustUploadFile) {
                errors.push({
                    message: 'Debe adjuntar un comprobante para el pago en Euros',
                    step: 3
                })
            }

            return errors

        },
        path() {
            let type = this.type === 'cash' ? 2 : 1

            return `${this.$route.path}?tipo=${type}&monto=${this.amount}&concepto=${this.concept}`
        },
        scheduleMessage() {
            return false;
        }
    },
    methods: {
        updateUserWithpersonaSuccess() {
            this.$root.user.user_verifications.push({
                provider: 'withpersona',
            })
        },
        getAccounts() {

            this.$http.get('api/account/index', {
                params: {
                    token: this.$root.auth.token
                }
            })
                .then(res => {
                    this.euroAccounts = res.data
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        this.$buefy.toast.open({
                            type: 'is-warning',
                            position: 'is-top-right',
                            duration: 3000,
                            message: error.response.data.message
                        })

                        this.$router.push({
                            name: 'Dashboard'
                        })
                    }
                })

        },

        getUserAccounts() {

            this.$http.get('api/bank/account/index', {
                params: {
                    token: this.$root.auth.token
                }
            })
                .then(res => {
                    this.userAccounts = res.data.accounts

                    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'tipo')) {
                        parseInt(this.$route.query.tipo) === 1 ? this.type = 'bank' : 'cash'
                    }

                    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'concepto')) {
                        this.concept = this.$route.query.concepto
                    }

                    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'cuenta') && Object.prototype.hasOwnProperty.call(this.$route.query, 'monto')) {
                        this.amount = parseFloat(this.$route.query.monto)
                        this.userAccounts.forEach((item, index) => {
                            if (`${item.id}` === `${this.$route.query.cuenta}`) {
                                this.modalSelect = index
                                this.modalNumber = this.$route.query.monto
                                this.modal = true
                            }
                        })
                    } else if (Object.prototype.hasOwnProperty.call(this.$route.query, 'monto')) {
                        this.amount = parseFloat(this.$route.query.monto)
                    }

                })
                .catch(error => {
                    if (error.response.status === 404) {

                        this.accountsUnavailable = true

                    }
                })

        },

        getRate() {
            this.$http.get('api/rate', {
                params: {
                    token: this.$root.auth.token
                }
            })
                .then(res => {
                    this.rate = res.data
                })
            this.$http.get('api/rate?type=cash', {
                params: {
                    token: this.$root.auth.token
                }
            })
                .then(({data}) => {
                    this.eurToUsdRate = data
                });
        },
        addAccount(item) {
            this.modal = false;
            this.accounts.push(item);
        },
        sendBank() {

            this.upload.loading = true

            let formData = new FormData()
            formData.append('amount', this.amount)
            formData.append('concept', this.concept)
            formData.append('type', this.type)
            formData.append('account', this.euroAccounts[this.euro].id)
            formData.append('accounts', JSON.stringify(this.accounts))
            if (this.mustUploadFile) {
                formData.append('capture', this.capture)
            }
            this.$http('api/transaction/store', {
                method: 'post',
                headers: {
                    'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
                onUploadProgress: event => {
                    if (event.lengthComputable) {

                        let percentage = Math.round((event.loaded * 100) / event.total)
                        percentage = percentage > 94 ? 94 : percentage

                        this.upload.value = percentage

                    } else {
                        this.upload.value = 94
                    }
                }
            })
                .then(res => {
                    this.upload.value = 100
                    this.upload.loading = false
                    this.$socket.emit('user notification', {
                        id: res.data.notification.id,
                        user: this.$root.user
                    })
                    if (res.data && res.data.redirect_url) {
                        window.location.href = res.data.redirect_url;
                        return;
                    }
                    this.$router.push({name: 'Transaction', params: {reference: res.data.reference}})
                    this.$root.transactions++
                })
                .catch(error => {
                    this.upload.loading = false

                    if (error.response.status === 403) {
                        this.$buefy.toast.open({
                            type: 'is-warning',
                            position: 'is-top-right',
                            duration: 3000,
                            message: error.response.data.message
                        })
                    }

                    if (error.response.status === 422) {
                        let errs = Object.values(error.response.data.errors);

                        errs.forEach(item => {
                            this.$buefy.toast.open({
                                type: 'is-danger',
                                message: item[0],
                                position: 'is-top-right',
                                duration: 3000
                            })
                        })
                    }
                })

        },

        showMessage() {

            if (!this.$cookies.isKey('is_first_transaction')) {
                this.$buefy.dialog.alert({
                    message: 'Al momento de hacer el pago a la cuenta de Euros, debes colocar por <b>concepto</b> tu número de <b>DNI</b>/<b>NIE</b>/<b>Pasaporte</b>, en la transacción bancaria',
                    type: 'is-info',
                    icon: 'info',
                    hasIcon: true,
                    confirmText: 'Entendido'
                })

                this.$cookies.set('is_first_transaction', this.$moment().format(), '365d')
            }

        },

        sendCash() {

            this.upload.loading = true

            let formData = new FormData()
            formData.append('amount', this.amount)
            formData.append('concept', this.concept)
            formData.append('type', this.type)
            formData.append('account', this.euroAccounts[this.euro].id)
            formData.append('beneficiary', this.dataCash.name)
            formData.append('dni', this.dataCash.dni)
            formData.append('city', this.dataCash.city)
            formData.append('phone', this.dataCash.phone)
            if (this.mustUploadFile) {
                formData.append('capture', this.capture)
            }

            this.$http('api/transaction/store', {
                method: 'post',
                headers: {
                    'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
                onUploadProgress: event => {
                    if (event.lengthComputable) {

                        let percentage = Math.round((event.loaded * 100) / event.total)
                        percentage = percentage > 94 ? 94 : percentage

                        this.upload.value = percentage

                    } else {
                        this.upload.value = 94
                    }
                }
            })
                .then(res => {

                    this.upload.value = 100
                    this.upload.loading = false

                    this.$socket.emit('user notification', {
                        id: res.data.notification.id,
                        user: this.$root.user
                    })
                    if (res.data.redirect_url) {
                        window.location.href = res.data.redirect_url;
                        return;
                    }
                    this.$router.push({name: 'Transaction', params: {reference: res.data.reference}})
                    this.$root.transactions++

                })
                .catch(error => {
                    this.upload.loading = false

                    if (error.response.status === 403) {
                        this.$buefy.toast.open({
                            type: 'is-warning',
                            position: 'is-top-right',
                            duration: 3000,
                            message: error.response.data.message
                        })
                    }

                    if (error.response.status === 422) {
                        let errs = Object.values(error.response.data.errors);

                        errs.forEach(item => {
                            this.$buefy.toast.open({
                                type: 'is-danger',
                                message: item[0],
                                position: 'is-top-right',
                                duration: 3000
                            })
                        })
                    }

                    if (error.response.status === 500) {
                        this.$buefy.toast.open({
                            type: 'is-danger',
                            message: '¡Hubo un error!, puede que tu transacción no se haya enviado. Por favor verifica y de ser así intentalo más tarde.',
                            position: 'is-top-right',
                            duration: 3000
                        })
                    }
                })

        },

        checkAmount() {
            if (this.amount < 20 && this.type === 'bank') {
                this.messages.amount = 'El monto debe ser mayor o igual a 20 €'
            } else if (this.amount < 100 && this.type === 'cash') {
                this.messages.amount = 'El monto debe ser mayor o igual a 100 €'
            } else {
                this.messages.amount = false
            }
        },

        toCodeString(string) {
            if (!string) return null;
            let vector = string.match(/.{1,4}/g);
            return vector.join(' ');
        },

        setPreview(event) {
            this.preview = URL.createObjectURL(event)
        },

        getCountries() {
            this.$http.get('api/country')
                .then(res => {
                    this.world = res.data
                })
        },

    },
    mounted() {

        if (!this.$root.isAuthenticate) {
            this.$router.push({
                name: 'Login'
            })
        }

        this.getCountries();
        this.getAccounts();
        this.getUserAccounts();
        this.getRate();

        if (this.scheduleMessage) {
            this.$buefy.dialog.alert({
                confirmText: 'Entendido',
                size: 'is-small',
                type: 'is-warning',
                message: `
              <p class="is-size-6 mt-0 mb-4 has-text-centered">
                Estas enviando fuera del horario de trabajo de <b>Cambiatuseuros</b>, tu transacción será registrada pero solo sera atendida dentro del mismo:
              </p>
              <p class="has-text-centered my-0">
                <b>Lunes a viernes:</b> 12:00pm – 10:00pm. <br>
                <b>Sábado:</b> 1:00pm – 9:00pm. <br>
                <i>Hora España</i>
              </p>
        `
            })
        }

    },
    name: 'NewTransaction',
    components: {
        SeleccionarCuentaBancaria,
        pdf,
        Identified,
        PendingTransactionsAlert,
        NumberInput
    }
}
</script>

<style lang="scss">
.boxed-outline {
    border: 2px dashed rgba(0, 0, 0, .3);
    border-radius: 5px;
    color: hsl(225, 67%, 40%);
    transition: .35s ease-out;
    cursor: pointer;

    &.disabled {
        color: rgb(105, 105, 120) !important;
    }
}

.boxed-dashed {
    border: 2px dashed rgba(0, 0, 0, .3);
    border-radius: 5px;
}

.boxed-outline-active {
    background-color: hsl(225, 67%, 40%) !important;
    color: hsl(0, 0%, 97%) !important;
    border-color: rgba(0, 0, 0, .7);
    box-shadow: 0 0 5px 0 lighten(hsl(225, 67%, 40%), 15%);
}
</style>