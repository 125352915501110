<template>
    <div class="container px-3">

        <div class="columns mt-4">
          <div class="column">
            <h1 class="title is-4 has-text-primary-dark">
              Historial de transacciones
            </h1>
          </div>
        </div>

        <div class="columns mt-2">

          <div class="column is-3 pt-3 order-2"
          v-if="!isNew">

            <p class="has-text-right has-text-weight-bold mr-1">
              <b-button type="is-info"
              size="is-small"
              icon-right="sliders-h"
              rounded
              :loading="sendLoading"
              :disabled="filter == null"
              @click="sendFilter"
              label="Filtrar"
              >
              </b-button>
            </p>
            
            <b-field
            label="Fecha:">
              <b-datepicker
              range
              placeholder="Rango de fecha..."
              position="is-bottom-left"
              rounded
              :max-date="new Date()"
              :mobile-native="false"
              v-model="range">
              </b-datepicker>
            </b-field>

            <b-field 
            label="Estado:">

              <b-dropdown v-model="filter"
              :mobile-modal="false"
              expanded>

                <template #trigger
                v-if="filter == 'complete'">
                  <b-button label="Completadas"
                  type="is-success"
                  icon-left="check"
                  expanded
                  rounded
                  >
                  </b-button>
                </template>

                <template #trigger
                v-else-if="filter == 'wait'">
                  <b-button label="En espera"
                  type="is-warning"
                  icon-left="clock"
                  expanded
                  rounded
                  >
                  </b-button>
                </template>

                <template #trigger
                v-else-if="filter == 'failed'">
                  <b-button label="Fallidas"
                  type="is-danger"
                  icon-left="times"
                  rounded
                  expanded
                  >
                  </b-button>
                </template>

                <template #trigger
                v-else-if="filter == null">
                  <b-button label="Seleciona un estado"
                  type="is-white"
                  rounded
                  expanded
                  class="has-text-grey-light"
                  >
                  </b-button>
                </template>

                <b-dropdown-item value="complete"
                class="pr-4">
                  <div class="media">
                    <b-icon class="media-left"
                    icon="check"
                    type="is-success"
                    size="is-medium"
                    >
                    </b-icon>
                    <div class="media-content text-desborder">
                      <h2>Completadas</h2>
                      <small>Transacciones finalizadas</small>
                    </div>
                  </div>
                </b-dropdown-item>

                <b-dropdown-item value="wait"
                class="pr-4">
                  <div class="media">
                    <b-icon class="media-left"
                    icon="clock"
                    type="is-warning"
                    size="is-medium"
                    >
                    </b-icon>
                    <div class="media-content text-desborder">
                      <h2>En espera</h2>
                      <small>Transacciones aún en espera</small>
                    </div>
                  </div>
                </b-dropdown-item>

                <b-dropdown-item value="failed"
                class="pr-4">
                  <div class="media">
                    <b-icon class="media-left"
                    icon="times"
                    type="is-danger"
                    size="is-medium"
                    >
                    </b-icon>
                    <div class="media-content text-desborder">
                      <h2>Fallidas</h2>
                      <small>Transacciones fallidas o rechazadas</small>
                    </div>
                  </div>
                </b-dropdown-item>


              </b-dropdown>

            </b-field>

          </div>

          <div :class="isNew ? 'column is-12' : 'column is-9'">
            <div class="box" 
            v-if="isReady && !isNew"
            >

              <time-line 
              v-for="(item, index) in transactions.data"
              :key="'timeline-' + index"
              @showIn="$router.push({ name: 'Transaction', params: { reference: item.reference } })">

                <template #content>
                  <div class="px-2">
                    <p class="is-size-7 has-text-primary mb-0">
                      <b>Código de referencia</b>
                    </p>
                    <h3 class="subtitle is-6 mb-2">
                      # {{ item.reference }}
                    </h3>

                    <div
                    v-if="item.accounts_count > 0">

                      <div v-for="(itemAccount, indexAccount) in item.accounts"
                      :key="'line-' + index + '-' + indexAccount">
                        <p class="is-size-7 has-text-primary mb-0">
                          <b>Beneficiario</b>
                        </p>
                        <p class="mb-1">
                          {{ itemAccount.beneficiary }}
                        </p>
                        <p>
                          <b>{{ banks[itemAccount.bank_id].name }}</b>
                        </p>
                      </div>

                    </div>

                    <div
                    v-else>

                      <div>
                        <p class="is-size-7 has-text-primary mb-0">
                          <b>Beneficiario</b>
                        </p>
                        <p class="mb-1">
                          {{ item.cash.beneficiary }}
                        </p>
                        <p class="has-text-info-dark">
                          <b>Retiro en efectivo</b>
                        </p>
                      </div>
                      
                    </div>

                  </div>
                </template>

                <template #timestamp>
                  <div class="px-2">
                    <h2 class="title is-5 mt-2 has-text-centered">
                      {{ $moment(item.created_at).format('D MMMM YYYY') }}
                    </h2>
                  </div>
                </template>

                <template #right>
                  <div class="mb-5">
                    <h3 class="title is-5 mt-2 has-text-centered mb-1">
                      {{ item.amount }} €
                    </h3>
                    <h3 class="title is-5 mt-2 has-text-centered">

                      <b-tooltip :label="item.sent_at !== null ? 'Finalizada' : 'En espera'"
                      :type="item.sent_at !== null ? 'is-success' : 'is-warning'"
                      position="is-bottom">
                        <b-icon :icon="item.sent_at !== null ? 'check' : 'clock'"
                        :type="item.sent_at !== null ? 'is-success' : 'is-warning'">
                        </b-icon>
                      </b-tooltip>

                    </h3>

                  </div>
                </template>

              </time-line>

              <infinite-loading
              @infinite="reload"
              :distance="20"
              >

                <template #no-more>
                  <b class="has-text-warning-dark pt-3" 
                  style="display: block">No hay más transacciones</b>
                </template>

                <template #spinner>
                  <b-icon
                  icon="spinner"
                  size="is-large"
                  class="fa-spin"
                  type="is-info"
                  >
                  </b-icon>
                </template>

              </infinite-loading>

            </div>

            <div v-else-if="isNew && isReady">
              <h2 class="subtitle is-4 mt-5 pt-3 mb-0 has-text-centered">
                No hay <b>transacciones</b> para mostrar
              </h2>

              <h2 class="has-text-centered">
                <b-icon
                icon="frown-open"
                class="has-text-warning-dark"
                size="is-large"
                >
                </b-icon>
              </h2>
            </div>

            <div class="box"
            v-else
            >

              <div class="mb-4">
                <b-skeleton
                height="150px"
                width="90%"
                position="is-centered"
                animated
                >
                </b-skeleton>
              </div>

              <div class="mb-4">
                <b-skeleton
                height="150px"
                width="90%"
                position="is-centered"
                animated
                >
                </b-skeleton>
              </div>

              <div class="mb-4">
                <b-skeleton
                height="150px"
                width="90%"
                position="is-centered"
                animated
                >
                </b-skeleton>
              </div>

              <div class="mb-4">
                <b-skeleton
                height="150px"
                width="90%"
                position="is-centered"
                animated
                >
                </b-skeleton>
              </div>

            </div>

          </div>

        </div>

        <b-modal v-model="search.modal"
        scroll="clip">

          <div class="box"
          style="max-width: 780px; margin: 0 auto">
            <b-table :data="search.data"
            hoverable
            paginated
            per-page="5"
            pagination-simple>

              <b-table-column field="ref"
              label="Referencia"
              v-slot="props"
              :width="100">
                <router-link :to="{name: 'Transaction', params:{reference: props.row.ref}}">
                  #{{ props.row.ref }}
                </router-link>
              </b-table-column>

              <b-table-column field="amount"
              label="Monto"
              v-slot="props"
              :width="50">
                {{ props.row.amount }} €
              </b-table-column>

              <b-table-column field="bolivares"
              label="Bólivares"
              v-slot="props"
              :width="150">
                {{ props.row.bolivares.toLocaleString('es-ES') }} Bs
              </b-table-column>

              <b-table-column field="state"
              label="Estado"
              v-slot="props"
              :width="80">
                <b-tag :type="props.row.color">
                  {{ props.row.state }}
                </b-tag>
              </b-table-column>

              <b-table-column field="created"
              label="Creada"
              v-slot="props"
              :width="150">
                {{ $moment(props.row.created).format('L') }}
              </b-table-column>

              <b-table-column field="type"
              label="Tipo"
              v-slot="props"
              :width="100">
                <b-tag type="is-info">
                  {{ props.row.type }}
                </b-tag>
              </b-table-column>

              <template #empty>
                <p class="mb-0 has-text-centered has-text-info">No hay coincidencias</p>
              </template>

            </b-table>
          </div>

          </b-modal>

    </div>
</template>

<script>
import TimeLine from '../components/TimeLine.vue'
import InfiniteLoading from 'vue-infinite-loading'
export default {
  watch: {
    open(){
      this.$parent.$parent.navbarZ()
    }
  },
  methods: {

      reload(_state){
        if(this.stepUrl !== null){
          setTimeout(() => {

            this.$http.get('api/transaction/index?page='+this.stepUrl, {
              params: {
                token: this.$root.auth.token
              }
            })
            .then(response => {

              response.data.transactions.data.forEach(element => {

                this.transactions.data.push(element);

              });

              _state.loaded();

              if(response.data.transactions.next_page_url !== null){
                this.stepUrl++
              } else {

                _state.complete()

              }

            })
        
          }, 300);  
        } else {
          _state.loaded()
          _state.complete()
        }     
      },

      sendFilter(){

        this.sendLoading = true;

        this.$http('api/transaction/filter', {
          method: 'post',
          headers: {
            'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
          },
          data: {
            state: this.filter,
            range: {
              from: this.range[0] == undefined ? null : this.$moment(this.range[0]).format(),
              to: this.range[1] == undefined ? null : this.$moment(this.range[1]).format()
            }
          }
        })
        .then(res => {

          this.sendLoading = false
          this.search.data = []

          res.data.forEach(item => {

            this.search.data.push({
              ref: item.reference,
              amount: item.amount,
              bolivares: item.bolivares,
              state: item.sent_at != null ? 'Completada' : item.failed ? 'Fallida' : 'En espera',
              type: item.cash == null ? 'Transferencia bancaria' : 'Retiro en efectivo',
              color: item.sent_at != null ? 'is-success' : item.failed ? 'is-danger' : 'is-warning',
              created: item.created_at
            })

          })

          this.search.modal = true

        })

      }
  },

  beforeMount() {
    if (! this.$root.isAuthenticate) {
      this.$router.push({name: 'Login'});
    }
  },

  mounted () {

    this.$http.get('api/transaction/index', {
      params: {
        token: this.$root.auth.token
      }
    })
    .then(response => {

      this.transactions = response.data.transactions;
      
      if(response.data.transactions.next_page_url !== null) {
        this.stepUrl++
      } else {
        this.stepUrl = null
      }

      let banks = response.data.banks;

      for (let i = 0; i < banks.length; i++) {

        this.banks[banks[i].id] = banks[i];

      }

      this.isReady = true;

    })
    .catch(error => {

      if(error.response.status == 404){
        this.isNew = true;
        this.isReady = true;
      }

    })

  },

  computed: {
    open(){
      return this.search.modal
    }
  },
  data () {
    return {
      isReady: false,
      transactions: {},
      banks: [],
      stepUrl: 1,

      isNew: false,
      
      filter: null,
      range: [],
      search: {
        modal: false,
        data: []
      },
      sendLoading: false,
    }
  },

  name: 'History',
  components: {
      TimeLine,
      InfiniteLoading
  }
    
}
</script>
