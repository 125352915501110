<template>
    <div class="is-relative">

        <div class="container">
            <div class="row mt-4">
                <div class="column">
                    <h2 class="title has-text-centered has-text-primary-dark mb-2">Regístrate ahora!</h2>
                    <h2 class="title has-text-centered has-text-primary-dark is-4">gestiona tus cambios desde tu
                        perfil</h2>
                    <div>
                        <h3 class="subtitle has-text-centered">¿Ya tienes una cuenta?
                            <router-link tag="a"
                                         :to="{ path: '/ingresar' }">Inicia sesión
                            </router-link>
                        </h3>
                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <div class="column is-10-tablet is-9-widescreen mx-auto" id="container-form-login">
                    <div class="row">
                        <div class="columns">
                            <div class="column">

                                <b-steps :has-navigation="false"
                                         type="is-warning"
                                         v-model="step"
                                         animated>
                                    <b-step-item label="Regístrarse"
                                                 icon="user-alt"></b-step-item>
                                    <b-step-item label="Datos personales"
                                                 icon="address-card"></b-step-item>
                                    <b-step-item label="Confirmar correo"
                                                 icon="check"
                                                 type="is-success"></b-step-item>
                                </b-steps>

                            </div>
                        </div>
                    </div>
                    <div class="box mb-6 pl-3">

                        <div class="row">
                            <div class="columns">

                                <div class="column is-6 mobile-none is-flex is-align-items-center pl-0">
                                    <b-image :src="require('../assets/register.png')">

                                    </b-image>
                                </div>
                                <div class="column is-6">
                                    <template>

                                        <section class="section">

                                            <slide-x-right-transition group
                                                                      :duration="300"
                                                                      tag="div">
                                                <template v-if="step === 0">
                                                    <h2 class="title is-4 has-text-primary-dark"
                                                        key="register-title">Regístrarse</h2>
                                                    <form class="form"
                                                          key="register-form">

                                                        <b-field label="Correo electrónico"
                                                                 label-position="on-border"
                                                                 :type="validate.email.type"
                                                                 :message="validate.email.message">

                                                            <b-input v-model="email"
                                                                     type="email"
                                                                     icon="envelope"
                                                                     rounded
                                                                     placeholder="ejemplo@correo.com"
                                                                     @input="verifyAvailableEmail"
                                                                     :loading="isLoadingEmail"></b-input>

                                                        </b-field>

                                                        <b-field label="Contraseña"
                                                                 label-position="on-border">

                                                            <b-input v-model="password"
                                                                     type="password"
                                                                     icon="key"
                                                                     rounded
                                                                     password-reveal
                                                                     placeholder="Crea tú contraseña"
                                                                     minlength="8"></b-input>

                                                        </b-field>

                                                        <b-field label="Repita su contraseña"
                                                                 label-position="on-border"
                                                                 :type="validate.password.type"
                                                                 :message="validate.password.message">

                                                            <b-input v-model="repeat"
                                                                     type="password"
                                                                     icon="key"
                                                                     rounded
                                                                     placeholder="Repite tú contraseña"
                                                                     @input="verifyPassword"></b-input>

                                                        </b-field>

                                                        <b-field>
                                                            <b-checkbox v-model="protection"
                                                                        type="is-success">
                                                                <small class="is-size-7">Acepta y confirma nuestra <a
                                                                    href="https://cambiatuseuros.com/politica-de-privacidad">Política
                                                                    de privacidad</a>, <a
                                                                    href="https://cambiatuseuros.com/aviso-legal">Aviso
                                                                    legal</a> y <a
                                                                    href="https://cambiatuseuros.com/politica-de-cookies">Política
                                                                    de cookies</a></small>
                                                            </b-checkbox>
                                                        </b-field>

                                                        <b-field>
                                                            <b-checkbox v-model="account"
                                                                        type="is-success">
                                                                <small class="is-size-7">Confirmo que soy titular de la
                                                                    cuenta en euros desde donde envió los pagos (de lo
                                                                    contrario no sé podrá realizar el cambio).</small>
                                                            </b-checkbox>
                                                        </b-field>

                                                        <b-field>
                                                            <b-checkbox v-model="marketing"
                                                                        type="is-success">
                                                                <small class="is-size-7">Deseo recibir comunicaciones
                                                                    comerciales sobre servicios de <b>CAMBIA TUS
                                                                        EUROS</b>.</small>
                                                            </b-checkbox>
                                                        </b-field>

                                                    </form>
                                                </template>
                                            </slide-x-right-transition>

                                            <slide-x-right-transition group
                                                                      :duration="300"
                                                                      tag="div">

                                                <template v-if="step === 1">
                                                    <h2 class="title is-4 has-text-primary-dark"
                                                        key="register-title">Información personal</h2>
                                                    <form class="form"
                                                          key="register-form">

                                                        <b-field label="Nombre"
                                                                 label-position="on-border"
                                                                 :type="validate.name.type"
                                                                 :message="validate.name.message">
                                                            <b-input type="text"
                                                                     rounded
                                                                     v-model="name"
                                                                     placeholder="Carlos José"
                                                                     @input="verifyName"
                                                                     @focus="validate.name.info = true"
                                                                     @blur="validate.name.info = false"
                                                            >
                                                            </b-input>
                                                        </b-field>
                                                        <collapse-transition
                                                            :duration="200">
                                                            <p class="is-size-7 has-text-info"
                                                               style="margin-top: -1rem"
                                                               v-show="validate.name.info">
                                                                Por favor, coloca tus <b>Nombres</b> exactamente igual
                                                                como se ven en tu <b>documento de identidad</b>
                                                            </p>
                                                        </collapse-transition>

                                                        <b-field label="Apellidos"
                                                                 label-position="on-border"
                                                                 :type="validate.lastname.type"
                                                                 :message="validate.lastname.message">
                                                            <b-input type="text"
                                                                     rounded
                                                                     v-model="lastname"
                                                                     placeholder="Pérez Hernández"
                                                                     @focus="validate.lastname.info = true"
                                                                     @blur="validate.lastname.info = false"
                                                                     @input="verifyLastname"
                                                            >
                                                            </b-input>
                                                        </b-field>

                                                        <collapse-transition
                                                            :duration="200">
                                                            <p class="is-size-7 has-text-info"
                                                               style="margin-top: -1rem"
                                                               v-show="validate.lastname.info">
                                                                Por favor, coloca tus <b>Apellidos</b> exactamente igual
                                                                como se ven en tu <b>documento de identidad</b>
                                                            </p>
                                                        </collapse-transition>

                                                        <b-field label="País de residencia"
                                                                 label-position="on-border">

                                                            <b-select v-model="residence"
                                                                      rounded
                                                                      expanded
                                                                      placeholder="Seleccione un país"
                                                                      icon="globe">

                                                                <option :value="item"
                                                                        v-for="item in ueCountries"
                                                                        :key="'country-'+item"
                                                                >
                                                                    {{ item }}
                                                                </option>
                                                            </b-select>

                                                        </b-field>

                                                        <b-field label="Ciudad"
                                                                 label-position="on-border">

                                                            <b-input
                                                                type="text"
                                                                rounded
                                                                placeholder="Ciudad de residencia"
                                                                v-model="city"
                                                            />

                                                        </b-field>

                                                        <b-field label="Provincia"
                                                                 label-position="on-border"
                                                        >
                                                            <b-input
                                                                type="text"
                                                                rounded
                                                                v-model="province"
                                                            />
                                                        </b-field>

                                                        <b-field label="Código postal"
                                                                 label-position="on-border">

                                                            <b-input
                                                                type="text"
                                                                rounded
                                                                v-model="post_code"
                                                                placeholder="000 000"
                                                            />

                                                        </b-field>

                                                        <b-field label="Calle"
                                                                 label-position="on-border">
                                                            <b-input v-model="direction"
                                                                     type="text"
                                                                     rounded
                                                                     required
                                                            />
                                                        </b-field>

                                                        <div class="is-flex is-justify-content-space-between">
                                                            <b-field label="Casa N°"
                                                                     label-position="on-border"
                                                                     style="width: 49%"
                                                            >
                                                                <b-input v-model="house_number"
                                                                         type="text"
                                                                         rounded
                                                                />
                                                            </b-field>

                                                            <b-field label="Piso/Puerta"
                                                                     label-position="on-border"
                                                                     style="width: 49%"
                                                            >
                                                                <b-input v-model="flat"
                                                                         type="text"
                                                                         rounded
                                                                />
                                                            </b-field>
                                                        </div>

                                                        <b-field label="País de nacimiento"
                                                                 label-position="on-border">

                                                            <b-dropdown expanded
                                                                        :mobile-modal="false"
                                                                        rounded
                                                                        v-model="nacionality"
                                                                        aria-role="list"
                                                                        @change="changeFlag"
                                                                        scrollable
                                                                        :max-height="300">

                                                                <template #trigger
                                                                          v-if="nacionality === null || nacionality === ''">
                                                                    <b-button rounded
                                                                              expanded
                                                                              outlined
                                                                              label="Selecciona un país"
                                                                              icon-right="globe"
                                                                    >
                                                                    </b-button>
                                                                </template>

                                                                <template #trigger
                                                                          v-else>
                                                                    <b-button rounded
                                                                              expanded
                                                                              type="is-success is-light">
                                                                        <img :src="flag"
                                                                             :alt="flag"
                                                                             :style="{ 'display': 'inline-block', 'height': '1.25rem !important', 'margin-bottom': '-.25rem' }"
                                                                             class="mr-2">
                                                                        <span>{{ nacionality }}</span>
                                                                    </b-button>
                                                                </template>

                                                                <b-dropdown-item v-for="item in world"
                                                                                 :key="`country-${item.iso}`"
                                                                                 :value="item.name"
                                                                                 aria-role="listitem">
                                                                    <div class="media">
                                                                        <img
                                                                            :src="`${apiURL}/country/flag?name=${item.flag}`"
                                                                            :alt="item.iso"
                                                                            :style="{ 'display': 'inline-block', 'height': '1rem' }"
                                                                            class="media-left">
                                                                        <div class="media-content">
                                                                            <h3>{{ item.name }}</h3>
                                                                        </div>
                                                                    </div>
                                                                </b-dropdown-item>

                                                            </b-dropdown>

                                                        </b-field>

                                                        <number-input
                                                            default-country="ES"
                                                            :countries="world"
                                                            v-model="phone"
                                                        />

                                                    </form>
                                                </template>

                                            </slide-x-right-transition>

                                            <slide-x-right-transition group
                                                                      :duration="300"
                                                                      tag="div">

                                                <template v-if="step === 2">
                                                    <h2 class="title is-4 mb-6 has-text-primary-dark"
                                                        key="register-title">Verificación de correo electrónico</h2>

                                                    <p key="register-text"
                                                       class="mb-5 subtitle is-6 has-text-centered">
                                                        Por favor, introduzca aquí el código de Verificación que hemos
                                                        enviado
                                                        a tu dirección de correo electrónico. <br>
                                                        <span class="has-text-info-dark">Recuerda revisar si el correo electrónico llegó a la bandeja de SPAM</span>
                                                    </p>

                                                    <form key="register-form"
                                                          class="form mb-4 is-relative">
                                                        <code-input
                                                            :fieldWidth="40"
                                                            :fieldHeight="40"
                                                            class="mx-auto mb-5"
                                                            @complete="sendCode">

                                                        </code-input>
                                                        <p>
                                                            ¿Tienes problemas con el código? <a @click.prevent="resend">Envíame
                                                            otro</a>
                                                        </p>
                                                        <b-loading
                                                            :is-full-page="false"
                                                            :can-cancel="false"
                                                            v-model="isLoadingCode"></b-loading>
                                                    </form>
                                                </template>

                                            </slide-x-right-transition>
                                            <div class="row mt-3">

                                                <b-button v-if="step !== 2"
                                                          type="is-success"
                                                          rounded
                                                          class="is-pulled-right"
                                                          @click="nextTo"
                                                          :disabled="step === 0 ? step0 : step1">
                                                    {{ buttonSend }}
                                                </b-button>

                                            </div>

                                        </section>


                                    </template>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" id="waves-yellow-2">
            <path fill="#f9f08b" fill-opacity="1"
                  d="M0,192L48,197.3C96,203,192,213,288,229.3C384,245,480,267,576,250.7C672,235,768,181,864,181.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" id="waves-blue-2">
            <path fill="#2244aa" fill-opacity="1"
                  d="M0,64L48,69.3C96,75,192,85,288,106.7C384,128,480,160,576,192C672,224,768,256,864,256C960,256,1056,224,1152,218.7C1248,213,1344,235,1392,245.3L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>

        <b-loading is-full-page
                   :can-cancel="false"
                   v-model="isLoading"
        >
        </b-loading>

    </div>
</template>

<script>
import NumberInput from '../components/NumberInput.vue'

export default {
    components: {NumberInput},
    created() {
        if (this.$root.isAuthenticate) {
            this.$router.push({name: 'Dashboard'});
        }
    },
    mounted() {
        this.getCountries();

        let e = window.Snap('#waves-yellow-2');
        let i = e.select('path');
        let init = i.attr('d');

        let z = window.Snap('#waves-blue-2');
        let y = z.select('path');
        let start = y.attr('d');

        window.setInterval(() => {
            let x = this.waves.yellow === 1 ? this.waves.yellow1 : this.waves.yellow === 2 ? this.waves.yellow2 : init;
            let s = this.waves.blue === 1 ? this.waves.blue1 : this.waves.blue === 2 ? this.waves.blue2 : start;

            i.animate({
                d: x
            }, 1500);

            y.animate({
                d: s
            }, 1500)

            this.waves.yellow === 1 ? this.waves.yellow = 2 : this.waves.yellow === 2 ? this.waves.yellow = 0 : this.waves.yellow = 1;
            this.waves.blue === 1 ? this.waves.blue = 2 : this.waves.blue === 2 ? this.waves.blue = 0 : this.waves.blue = 1;
        }, 1500);
    },
    methods: {
        preregister() {
            this.$http.post('api/user/preregister', {
                'email': this.email,
                'name': this.name,
                'lastname': this.lastname
            });
        },
        register() {
            this.isLoading = true;

            this.$http.post('api/user/store', {
                'email': this.email,
                'password': this.password,
                'name': this.name,
                'lastname': this.lastname,
                'phone': this.phone.replace(/\s/g, ''),
                'direction': this.direction,
                'city': this.city,
                'post_code': this.post_code,
                'flat': this.flat,
                'nacionality': this.nacionality,
                'residence': this.residence,
                'marketing': this.marketing,
                'province': this.province,
                'house_number': this.house_number
            })
                .then(response => {
                    if (response.status === 201) {
                        let token = response.data.token;
                        this.$root.auth.token = token;
                        this.$cookies.set('auth_token', token, '365d');

                        this.$socket.emit('user notification', {
                            id: response.data.notification.id,
                            user: response.data.notification.notificable_id
                        })

                        this.isLoading = false;
                        this.$router.push({name: 'Identified'});

                    }
                })
                .catch(error => {
                    let errors = Object.values(error.response.data.errors);

                    errors.forEach(item => {
                        this.$buefy.toast.open({
                            durarion: 4000,
                            message: item,
                            type: 'is-danger',
                            position: 'is-top-right'
                        });
                    })

                    this.isLoading = false;
                });

        },

        resend() {
            const loader = this.$buefy.loading.open();

            this.$http.get('api/auth/resend-verify-email', {
                params: {
                    token: this.$root.auth.token
                }
            })
                .then(res => {

                    loader.close();

                    this.$buefy.toast.open({
                        position: 'is-top-right',
                        message: res.data.message,
                        duration: 3000,
                        type: 'is-success'
                    })

                })
                .catch(error => {

                    loader.close();

                    this.$buefy.toast.open({
                        position: 'is-top-right',
                        message: 'Ha ocurrido un error al reenviar',
                        duration: 3000,
                        type: 'is-warning'
                    });

                    console.log(error.response);

                })
        },

        sendCode(code) {
            this.isLoadingCode = true;

            this.$http.get('api/auth/verify-email', {
                params: {
                    code: code,
                    token: this.$root.auth.token
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        this.isLoadingCode = false;

                        this.$buefy.snackbar.open({
                            durarion: 5000,
                            message: response.data.message,
                            type: 'is-success',
                            position: 'is-top-right'
                        });

                        this.$root.user.email_verified_at = new Date();

                        this.$router.push({name: 'Dashboard'});
                    }
                })
                .catch(error => {
                    this.isLoadingCode = false;

                    this.$buefy.snackbar.open({
                        durarion: 5000,
                        message: error.response.data.message,
                        type: 'is-danger',
                        position: 'is-top-right'
                    });
                })
        },

        verifyAvailableEmail() {
            this.isLoadingEmail = true

            this.$http.get('api/user/verify-available-email', {
                params: {
                    email: this.email
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        this.validate.email.type = 'is-success';
                        this.validate.email.message = null;
                    }
                    this.isLoadingEmail = false
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.validate.email.type = 'is-danger';
                        this.validate.email.message = error.response.data.errors.email[0];
                    }
                    this.isLoadingEmail = false
                })

        },

        verifyPassword() {
            if (this.password === this.repeat) {

                this.validate.password.type = 'is-success';
                this.validate.password.message = null;

            } else {
                this.validate.password.type = 'is-danger';
                this.validate.password.message = 'Los campos de contraseñas deben ser iguales';
            }
        },

        verifyName() {
            if (this.name.length < 3) {
                this.validate.name.type = 'is-danger';
                this.validate.name.message = 'El campo de Nombre de tener al menos 3 caracteres'
            } else {
                this.validate.name.type = 'is-success';
                this.validate.name.message = null
            }
        },

        verifyLastname() {
            if (this.lastname.length < 3) {
                this.validate.lastname.type = 'is-danger';
                this.validate.lastname.message = 'El campo de Apellidos de tener al menos 3 caracteres'
            } else {
                this.validate.lastname.type = 'is-success';
                this.validate.lastname.message = null
            }
        },
        nextTo() {
            switch (this.step) {
                case 0:

                    this.step++;
                    this.preregister();

                    break;
                case 1:

                    this.register();

                    break;

                default:
                    break;
            }
        },

        getCountries() {
            this.$http.get('api/country')
                .then(res => {
                    this.world = res.data
                })
        },

        changeFlag(value) {
            this.world.forEach(item => {
                if (item.name === value) {
                    this.flag = `${this.apiURL}/country/flag?name=${item.flag}`
                }
            })
        }
    },

    computed: {
        buttonSend() {
            return this.step < 1 ? 'Aceptar' : 'Enviar';
        },

        step0() {
            return !(this.validate.email.type === 'is-success' && this.validate.password.type === 'is-success' && this.account && this.protection);
        },
        step1() {
            return !(this.validate.name.type === 'is-success' && this.validate.lastname.type === 'is-success' && this.direction.length >= 1);
        },
        apiURL() {
            return process.env.VUE_APP_BASE_API;
        }
    },
    data() {
        return {
            email: '',
            password: '',
            name: '',
            lastname: '',
            phone: '',
            direction: '',
            repeat: '',
            residence: null,
            nacionality: null,
            city: '',
            province: '',
            house_number: '',
            flat: '',
            post_code: '',
            protection: false,
            account: false,
            marketing: false,

            maxDate: this.$moment().subtract(18, 'year')._d,

            step: 0,
            isLoading: false,
            isLoadingCode: false,
            isLoadingEmail: false,

            validate: {
                email: {
                    type: '',
                    message: null,
                },
                password: {
                    type: '',
                    message: null,
                },
                name: {
                    type: '',
                    message: null,
                    info: false
                },
                lastname: {
                    type: '',
                    message: null,
                    info: false
                }
            },

            translation: {
                countrySelectorLabel: 'Código',
                countrySelectorError: 'Selecciona un país',
                phoneNumberLabel: 'Número de teléfono',
                example: 'Ejemplo :'
            },

            waves: {
                yellow: 1,
                yellow1: 'M0,96L48,106.7C96,117,192,139,288,170.7C384,203,480,245,576,266.7C672,288,768,288,864,266.7C960,245,1056,203,1152,186.7C1248,171,1344,181,1392,186.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z',
                yellow2: 'M0,96L48,85.3C96,75,192,53,288,80C384,107,480,181,576,181.3C672,181,768,107,864,80C960,53,1056,75,1152,80C1248,85,1344,75,1392,69.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z',
                blue: 1,
                blue1: 'M0,64L48,69.3C96,75,192,85,288,74.7C384,64,480,32,576,69.3C672,107,768,213,864,218.7C960,224,1056,128,1152,122.7C1248,117,1344,203,1392,245.3L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z',
                blue2: 'M0,256L48,229.3C96,203,192,149,288,133.3C384,117,480,139,576,128C672,117,768,75,864,64C960,53,1056,75,1152,112C1248,149,1344,203,1392,229.3L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'
            },

            ueCountries:
                [
                    'Venezuela', 'Alemania', 'Austria', 'Bélgica', 'Bulgaria', 'Chequia', 'Chipre', 'Croacia', 'Dinamarca', 'Eslovaquia', 'Eslovenia', 'España', 'Estonia', 'Finlandia', 'Francia', 'Grecia', 'Hungría', 'Irlanda', 'Italia', 'Letonia', 'Lituania', 'Luxemburgo', 'Malta', 'Países Bajos', 'Polonia', 'Portugal', 'Rumanía', 'Suecia'
                ],

            typeOfDocuments:
                [
                    'DNI', 'NIE', 'Pasaporte'
                ],

            genders: {
                male: 'Hombre',
                female: 'Mujer',
                unknown: 'Prefiero no decirlo'
            },

            world: [],
            flag: null
        }
    },
    name: 'Logup'
}
</script>

<style lang="scss">
#number-input input {
    height: 42px !important;
}

.country-selector__list {
    border-radius: 5px !important;
}

#waves-yellow-2,
#waves-blue-2 {
    position: absolute !important;
    bottom: 0;
    left: 0;
    width: 100vw;
    opacity: .7;
}

#waves-yellow-2 {
    z-index: 10;
}

#waves-blue-2 {
    z-index: 5;
}

.container {
    z-index: 15;
}

.react-code-input input:first-child {
    border-radius: 50px 0 0 50px !important;
}

.react-code-input input:last-child {
    border-radius: 0 50px 50px 0 !important;
}
</style>
