<template>
    <div class="is-relative">
        <div class="container">
            <div class="row mt-4">
                <div class="column">    
                    <h2 class="title has-text-centered has-text-primary-dark">Cambiatuseuros, rápido y seguro</h2>
                    <div>
                        <h3 class="subtitle has-text-centered">¿no tienes usuario?
                            <router-link tag="a"
                            :to="{ path: '/registrarse' }">Registrate</router-link>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="column is-10-tablet is-8-widescreen mx-auto" id="container-form-login">
                    <div class="box mb-6 pl-3">
                        <div class="row">
                            <div class="columns">
                                <div class="column is-6 mobile-none is-flex is-align-items-center pl-0">
                                    <b-image :src="require('../assets/login.png')"
                                    >
                                    </b-image>
                                </div>
                                <div class="column is-6">
                                    <template>
                                        <div class="section">
                                            <h2 class="title is-4 has-text-primary-dark">Iniciar sesión</h2>
                                            <form action="" class="form">

                                                <b-field label="Correo electrónico"
                                                label-position="on-border">
                                                    <b-input type="email"
                                                    v-model="email"
                                                    icon="envelope"
                                                    rounded
                                                    placeholder="ejemplo@correo.com"
                                                    @input="validateData"
                                                    @keydown.native.enter="$refs.passwd.focus()"
                                                    >
                                                    </b-input>
                                                </b-field>

                                                <b-field label="Contraseña"
                                                label-position="on-border">
                                                    <b-input type="password"
                                                    v-model="password"
                                                    ref="passwd"
                                                    password-reveal
                                                    icon="key"
                                                    rounded
                                                    placeholder="Tú contraseña"
                                                    @input="validateData"
                                                    @keydown.native.enter="$refs.submitAction.$el.click()"
                                                    >
                                                    </b-input>
                                                </b-field>

                                            </form>
                                            <div class="row mt-6">
                                                <div class="columns">
                                                    <div class="column is-flex is-align-items-center">
                                                        <router-link tag="a"
                                                        :to="{ name: 'ResetPassword' }"
                                                        class="ml-auto has-text-right mr-2">
                                                            ¿Olvidó su contraseña?
                                                        </router-link>
                                                    </div>
                                                    <div class="column">
                                                        <b-button type="is-primary"
                                                        rounded
                                                        expanded
                                                        :disabled="validate"
                                                        ref="submitAction"
                                                        @click="login">
                                                            Entrar
                                                        </b-button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" id="waves-yellow">
            <path fill="#f9f08b" fill-opacity="1" d="M0,192L48,197.3C96,203,192,213,288,229.3C384,245,480,267,576,250.7C672,235,768,181,864,181.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" id="waves-blue">
            <path fill="#2244aa" fill-opacity="1" d="M0,64L48,69.3C96,75,192,85,288,106.7C384,128,480,160,576,192C672,224,768,256,864,256C960,256,1056,224,1152,218.7C1248,213,1344,235,1392,245.3L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>

        <b-loading is-full-page
        :can-cancel="false"
        v-model="isLoading"
        >
        </b-loading>
        
    </div>
</template>

<script>
export default {
  created () {
      if (this.$root.isAuthenticate) {
        this.$router.push({name: 'Dashboard'});
      }
  },
  methods: {
      validateData () {
          let exp = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

          if (exp.test(this.email) && this.password.length >= 8) {
              this.validate = false
          } else {
              this.validate = true
          }
      },

      login () {
          this.isLoading = true;

          this.$http.post('api/auth/login', {
              'email': this.email,
              'password': this.password
          })
          .then(response => {
              if (response.status == 200) {
                  this.$root.auth.token = response.data.access_token;
                  this.$cookies.set('auth_token', response.data.access_token, '365d');
                  
                  this.isLoading = false;

                  this.$router.push({name: 'Dashboard'});
              }
          })
          .catch(error => {
              if (error.response.status == 401) {
                  this.isLoading = false;

                  this.$buefy.toast.open({
                      message: 'Datos de autenticación invalidos',
                      type: 'is-danger',
                      duration: 4000,
                      position: 'is-top-right'
                  })
              }
          })
      }
  },
  mounted () {
        let e = window.Snap('#waves-yellow');
        let i = e.select('path');
        let init = i.attr('d');

        let z = window.Snap('#waves-blue');
        let y = z.select('path');
        let start = y.attr('d');

        window.setInterval(() => {
            let x = this.waves.yellow === 1 ? this.waves.yellow1 : this.waves.yellow === 2 ? this.waves.yellow2 : init;
            let s = this.waves.blue === 1 ? this.waves.blue1 : this.waves.blue === 2 ? this.waves.blue2 : start;

            i.animate({
                d: x
            }, 1500);

            y.animate({
                d: s
            }, 1500)

            this.waves.yellow === 1 ? this.waves.yellow = 2 : this.waves.yellow === 2 ? this.waves.yellow = 0 : this.waves.yellow = 1;
            this.waves.blue === 1 ? this.waves.blue = 2 : this.waves.blue === 2 ? this.waves.blue = 0 : this.waves.blue = 1;
        }, 1500);
  },
  data () {
    return {
        email: '',
        password: '',

        isLoading: false,

        waves: {
            yellow: 1,
            yellow1: 'M0,96L48,106.7C96,117,192,139,288,170.7C384,203,480,245,576,266.7C672,288,768,288,864,266.7C960,245,1056,203,1152,186.7C1248,171,1344,181,1392,186.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z',
            yellow2: 'M0,96L48,85.3C96,75,192,53,288,80C384,107,480,181,576,181.3C672,181,768,107,864,80C960,53,1056,75,1152,80C1248,85,1344,75,1392,69.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z',
            blue: 1,
            blue1: 'M0,64L48,69.3C96,75,192,85,288,74.7C384,64,480,32,576,69.3C672,107,768,213,864,218.7C960,224,1056,128,1152,122.7C1248,117,1344,203,1392,245.3L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z',
            blue2: 'M0,256L48,229.3C96,203,192,149,288,133.3C384,117,480,139,576,128C672,117,768,75,864,64C960,53,1056,75,1152,112C1248,149,1344,203,1392,229.3L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'
        },

        validate: true
    }
  },
	name: 'Login' 
}
</script>
<style lang="scss">
    #waves-yellow, 
    #waves-blue{
        position: absolute !important;
        bottom: 0;
        left: 0;
        width: 100vw;
        opacity: .7;
    }

    #waves-yellow{
        z-index: 5;
    }
    #waves-blue{
        z-index: 10;
    }
    .container{
        z-index: 15;
    }
</style>