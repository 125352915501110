<template>
  <div id="app">

    <!-- Navbar -->
    <nav-bar
    ref="navbar"
    :style="z == true ? 'z-index: 15' : ''"
    v-if="showNavBar">
    </nav-bar>

    <div class="columns is-mobile" id="layaout">

      <div id="container-sidebar" 
      v-if="showSideBar"
      ref="sidebar"
      :style="{
        'left': leftCSS,
      }">

        <side-bar
        >
        </side-bar>

      </div>

      <div class="column" 
      id="container-main">

          <AccountDeletionRequestSentMessage
              class="my-2 mx-2"
              v-if="$root.user.deletion_request"
          />
        <fade-transition :duration="150">

          <router-view></router-view>
        </fade-transition>

        <chat :messages="$root.chat.content"
        v-if="$root.chat.display"
        @close="$root.chat.display = false"
        @send="$root.sendMessage">

          <template #header-icon>
            <b-icon icon="times"
            type="is-danger"
            >
            </b-icon>
          </template>

        </chat>

      </div>

    </div>

    <div class="wa-button">
        <b-button
            type="is-success"
            size="is-large"
            icon-pack="fab"
            icon-left="whatsapp"
            rounded
            class="p-5"
            role="link"
            tag="a"
            href="https://wa.me/34672353172"
            target="_blank"
        ></b-button>
    </div>

    <b-modal width="420px"
    scroll="clip"
    v-model="$root.notifications.modal"
    v-if="$root.notifications.show != null"
    >
      <div class="box mb-0">
        <h2 class="subtitle has-text-primary-dark">
          {{ $root.notifications.content[$root.notifications.show].title }}
        </h2>

        <p>
          {{ $root.notifications.content[$root.notifications.show].body }}
        </p>
        <b-field class="is-flex is-justify-content-flex-end px-3">
          <b-button rounded
          label="Ver"
          icon-right="eye"
          type="is-success"
          size="is-small"
          @click="showNotification()"
          >
          </b-button>
        </b-field>
      </div>
    </b-modal>

  </div>
</template>

<script>
import Chat from './components/Chat.vue'
import AccountDeletionRequestSentMessage from "./views/AccountDeletionRequest/AccountDeletionRequestSentMessage.vue";
export default {
  data () {
    return {

      visible: false,
      leftCSS: null,
      z: false,

    }
  },
  computed: {
    showNavBar(){
      return this.$root.toComplete === false && this.$route.name != 'ToCompletePre' ? true : false
    },
    showSideBar(){
      return this.$root.toComplete === false && this.$root.isAuthenticate && this.$route.name != 'Logup' && this.$route.name != 'ToCompletePre' ? true : false
    },
  },
  methods: {

    sidebar() {
      
      if(!this.visible){

        this.leftCSS = '0rem'
        this.visible = true;

      } else {

        this.leftCSS = '-18rem'
        this.visible = false;

      }

    },

    showNotification(){
      if(this.$root.notifications.content[this.$root.notifications.show].notificable_type == "App\\Transaction"){
        this.$router.push({
          name: 'Transaction',
          params: {
            reference: this.$root.notifications.content[this.$root.notifications.show].notificable.reference
          }
        })
      } else if(this.$root.notifications.content[this.$root.notifications.show].notificable_type == "App\\Admin"){
        this.$router.push({
          name: 'Profile',
        })
      }
      this.$root.notifications.modal = false
    },

    navbarZ(){
      this.z == true ? this.z = false : this.z = true;
    }

  },
  components: {
      AccountDeletionRequestSentMessage,
    Chat
  }
  
}
</script>

<style lang="scss">
  .sidebar-list{
    a{
      color: hsl(0, 0%, 97%);
    }

    li{
      padding: .5rem .75rem;
      margin-bottom: .5rem;
      border-radius: 5rem;

      transition: background .25s ease-in-out;

      text-align: center;

      cursor: pointer;
    }

    li:hover{
      background-color: hsl(0, 0%, 95%);

      a{
        color: hsl(0, 0%, 10%);
      }
    }

  }

  #container-sidebar{
    padding: 0 !important;
    min-height: 95vh;
  }

  #container-main{
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }

  .avatar{
    border: 3px solid hsl(55, 90%, 76%);
  }

  .wa-button {
      position: fixed;
      right: 1rem;
      bottom: 1rem;
      z-index: 100;
  }
</style>
