<template>
    <div class="container mt-4 px-3"
        v-if="isReady">

        <div class="columns">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark">
                    Transacción
                </h1>
            </div>
        </div>

        <div class="columns mt-4">

            <div class="column is-8 is-offset-2">
                <div class="box">

                    <b-tabs type="is-boxed"
                        v-model="step"
                        expanded>

                        <b-tab-item label="Detalles"
                        icon="coins">

                            <div class="columns is-mobile is-flex-wrap-wrap mt-4 px-4 pb-0">

                                <div class="column is-9 is-flex is-align-items-center">
                                    <b-tooltip :label="transaction.accounts_count > 0 ? 'Transferencia bancaria' : transaction.cash_count > 0 ? 'Retiro en efectivo' : 'Código de referencia'"
                                    type="is-primary"
                                    position="is-bottom">
                                        <h2 class="subtitle has-text-primary is-4">
                                            # {{ transaction.reference }}
                                        </h2>
                                    </b-tooltip>
                                </div>

                                <div class="column is-3 is-flex is-align-items-center is-justify-content-center">
                                    <h2 class="subtitle has-text-primary is-4">
                                        <b-tooltip :label="transaction.sent_at !== null ? 'Finalizada' : 'En espera'"
                                        :type="transaction.sent_at !== null ? 'is-success' : 'is-warning'"
                                        position="is-bottom">
                                            <b-icon
                                            :type="transaction.failed ? 'is-danger' : transaction.sent_at == null ? 'is-warning' : 'is-success'"
                                            :icon="transaction.failed ? 'times' : transaction.sent_at == null ? 'clock' : 'check'"
                                            size="is-medium"
                                            >
                                            </b-icon>
                                        </b-tooltip>
                                    </h2>
                                </div>

                                <div class="column is-12">
                                    <ul class="mb-4 px-3">
                                        <li class="is-size-7 has-text-primary-dark">
                                            Monto enviado:
                                        </li>
                                        <li class="is-size-5">
                                            {{ transaction.amount.toLocaleString('es-ES') }} €
                                        </li>
                                    </ul>

                                    <ul class="mb-4 px-3">
                                        <li class="is-size-7 has-text-primary-dark">
                                            Monto recibido:
                                        </li>
                                        <li>
                                            {{ transaction.bolivares.toLocaleString('es-ES') }} 
                                            <span v-if="type == 'bank'">
                                                Bs
                                            </span>
                                            <span v-else>
                                                USD
                                            </span>
                                        </li>
                                    </ul>

                                    <ul class="px-3 mb-4">
                                        <li class="is-size-7 has-text-primary-dark">
                                            Concepto:
                                        </li>
                                        <li>
                                            <i>{{ transaction.concept == null || transaction.concept == 'null' || transaction.concept == '' ? 'No se especifíco un concepto' : transaction.concept }}</i>
                                        </li>
                                    </ul>
                                </div>

                                <div class="column is-12 pb-0">
                                    <p class="has-text-centered mb-1 has-text-grey is-size-7">
                                        <b>Enviado: </b>{{ $moment(transaction.created_at).format('DD MMMM [de] YYYY, hh:mm a') }}
                                    </p>
                                    <p class="has-text-centered has-text-grey is-size-7"
                                    v-if="transaction.sent_at != null">
                                        <b>Recibido: </b>{{ $moment(transaction.sent_at).format('DD MMMM [de] YYYY, hh:mm a') }}
                                    </p>

                                </div>

                            </div>

                        </b-tab-item>

                        <b-tab-item label="Beneficiario(s)"
                        icon="money-bill-wave">

                            <div class="columns is-flex-wrap-wrap mt-5">

                                <div class="column is-12">
                                    <ul v-if="type == 'bank'">
                                        <li class="is-size-7 has-text-primary-dark mb-3 has-text-centered">
                                            Beneficiario(s):
                                        </li>
                                        <div class="boxed-dashed p-4 mb-3 mx-auto"
                                        style="max-width: 420px"
                                        v-for="item in transaction.accounts"
                                        :key="'beneficiary-'+item.id">
                                            <li class="is-size-7 has-text-success-dark">
                                                Nombre y Apellidos:
                                            </li>
                                            <li class="mb-3">
                                                {{ item.beneficiary }}
                                            </li>

                                            <li class="is-size-7 has-text-success-dark">
                                                Código de cuenta:
                                            </li>
                                            <li class="has-text-weight-bold mb-3">
                                                {{ toCodeString(item.code) }}
                                            </li>

                                            <li class="is-size-7 has-text-success-dark">
                                                Monto:
                                            </li>
                                            <li class="mb-3">
                                                {{ item.pivot.amount }} €
                                            </li>
                                            <li class="is-size-7 has-text-success-dark">
                                                Recibido:
                                            </li>
                                            <li>
                                                {{ bolivar * item.pivot.amount }} Bs
                                            </li>
                                        </div>
                                    </ul>

                                    <ul v-else-if="type == 'cash'">

                                        <li class="is-size-7 has-text-primary-dark mb-3 has-text-centered">
                                            Beneficiario:
                                        </li>

                                        <div class="boxed-dashed p-4 mb-3 mx-auto"
                                        style="max-width: 420px">    
                                            <li class="is-size-7 has-text-success-dark">
                                                Nombre y Apellidos:
                                            </li>       
                                            <li class="mb-3">
                                                {{ transaction.cash.beneficiary }}
                                            </li>

                                            <li class="is-size-7 has-text-success-dark">
                                                Cédula/RIF:
                                            </li>
                                            <li class="mb-3">
                                                {{ transaction.cash.dni }}
                                            </li>

                                            <li class="is-size-7 has-text-success-dark">
                                                Teléfono:
                                            </li>
                                            <li class="mb-3 is-size-7">
                                                {{ transaction.cash.phone || 'Número de teléfono no disponible' }}
                                            </li>

                                            <li class="is-size-7 has-text-success-dark">
                                                Ciudad:
                                            </li>
                                            <li class="mb-0">
                                                <b>{{ transaction.cash.city }}</b>
                                            </li>
                                        </div>
         
                                    </ul>
                                </div>

                                <div class="column is-12">
                                    <p class="is-size-7 has-text-primary-dark mb-3 has-text-centered">
                                        Cuenta de pago en Euros:
                                    </p>

                                    <div class="boxed-dashed p-4 mb-3 mx-auto"
                                    style="max-width: 420px">
                                        <ul v-if="transaction.euro_account != null">
                                            <li class="mb-0 is-size-7 has-text-danger-dark">
                                                Banco:
                                            </li>
                                            <li class="mb-3">
                                                {{ transaction.euro_account.bank }}
                                            </li>
                                            <li class="mb-0 is-size-7 has-text-danger-dark">
                                                IBAN:
                                            </li>
                                            <li class="mb-3">
                                                <b>{{ transaction.euro_account.iban }}</b>
                                            </li>

                                            <li class="mb-0 is-size-7 has-text-danger-dark">
                                                SWIFT:
                                            </li>
                                            <li class="mb-3">
                                                {{ transaction.euro_account.swift }}
                                            </li>

                                            <li class="mb-0 is-size-7 has-text-danger-dark">
                                                Titular:
                                            </li>
                                            <li class="mb-3">
                                                {{ transaction.euro_account.name }}  {{ transaction.euro_account.lastname }}
                                            </li>
                                        </ul>

                                        <p class="has-text-centered has-text-grey py-5"
                                        v-else>
                                            <i>Cuenta no disponible por ahora</i>
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </b-tab-item>

                        <b-tab-item label="Facturación"
                        icon="file-invoice-dollar">

                            <div class="columns my-4">
                                <div class="column is-8 is-offset-2">
                                    <div class="has-text-centered">
                                        <b-button label="Factura"
                                        icon-right="file-invoice"
                                        rounded
                                        type="is-info"
                                        @click="modal.invoice = true"
                                        size="is-small"
                                        >
                                        </b-button>
                                    </div>
                                </div>
                            </div>

                            <h2 class="subtitle is-6 has-text-primary-dark mt-5 px-3">
                                Comprobante de transacción
                            </h2>

                            <div class="columns px-4">

                                <div class="column"
                                v-for="(item, index) in images"
                                :key="item.basename"
                                :class="images.length > 1 ? 'is-6' : 'is-8 is-offset-2'">

                                    <figure class="image show-preview-transaction-credential"
                                    v-if="typeImages[index] == 'image'">
                                        <img :src="item.url"
                                        :alt="item.filename">

                                        <span class="icon has-text-info-light"
                                        @click="showPreview(item.url, typeImages[index])">
                                            <i class="fas fa-eye"></i>
                                        </span>
                                    </figure>

                                    <figure class="show-preview-transaction-credentials-pdf">

                                        <pdf :src="item.url"
                                        >
                                        </pdf>

                                        <div class="background-pdf"></div>

                                        <span class="icon">

                                            <i class="fas fa-eye has-text-info-light" 
                                            @click="showPreview(item.url, typeImages[index])"
                                            >
                                            </i>

                                            <i class="fas fa-download ml-2 has-text-success"
                                            role="link"
                                            aria-label="Download"
                                            @click="downloadFile(item.url, `${transaction.reference}.${item.extension}`)"
                                            >
                                            </i>

                                        </span>

                                    </figure>

                                </div>

                            </div>

                        </b-tab-item>


                    </b-tabs>

                </div>
            </div>

        </div>

        <b-modal scroll="clip"
        v-model="modal.preview"
        :width="720">

            <b-image :src="preview"
            v-if="typePreview == 'image'"
            >
            </b-image>

            <div class="box mb-0"
            v-else-if="typePreview == 'pdf'">
                <pdf :src="preview"
                >
                </pdf>
            </div>

        </b-modal>

        <b-modal scroll="clip"
        v-model="modal.invoice"
        :width="640">
            <div class="box mb-0">
                <div class="px-4 has-text-right">
                    <b-button label="Descargar"
                    icon-right="download"
                    type="is-info"
                    rounded
                    size="is-small"
                    @click="$refs.pdfInvoice.generatePdf()"
                    >
                    </b-button>
                </div>

                <section class="py-5 px-4">

                    <div class="columns is-mobile">
                        <div class="column is-8">
                            <h3 class="subtitle has-text-weight-bold is-4 mb-3">
                                Cambiatuseuros, S.L.
                            </h3>
                            <ul class="is-size-7">
                                <li>
                                    <b>CIF:</b> B88556501
                                </li>

                                <li>
                                    <b>Teléfono:</b> +34 603 17 62 56
                                </li>

                                <li>
                                    <b>E-mail:</b> info@cambiatuseuros.com
                                </li>

                            </ul>
                        </div>
                        <div class="column is-4">
                            <img :src="require('../assets/logo.png')" 
                            alt="logo de Cambiatuseuros"
                            style="max-width: 120px; display: block"
                            class="mx-auto">
                        </div>
                    </div>
                    
                    <div class="line-divider my-3"></div>

                    <div class="columns is-mobile px-3">
                        <div class="column is-6">
                            <h3 class="subtitle mb-2 has-text-info-dark">
                                Facturar a:
                            </h3>
                            <ul class="is-size-7">
                                <li>    
                                    <b>Nombre:</b> {{ $root.user.name }} {{ $root.user.lastname }}
                                </li>

                                <li>    
                                    <b>Documento N°:</b> {{ $root.user.dni }}
                                </li>

                                <li>
                                    <b>Teléfono:</b> {{ $root.user.phone }} 
                                </li>

                                <li>
                                    <b>Dirección:</b> {{ $root.user.direction }}, {{ $root.user.flat }}
                                </li>

                                <li>
                                    <b>País de residencia:</b> {{ $root.user.residence }}
                                </li>

                                <li>
                                    <b>Ciudad/Región:</b> {{ $root.user.city }}
                                </li>

                            </ul>
                        </div>

                        <div class="column is-6">
                            <div class="ml-auto is-pulled-right"
                            style="display: inline-block">
                                <p class="mb-0 is-size-7 has-text-weight-bold">
                                    Número:
                                </p>
                                <h3 class="subtitle mb-2 is-4 has-text-info-dark has-text-weight-bold">
                                    {{ transaction.reference }}
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-12">

                            <h3 class="subtitle mb-2 has-text-info-dark has-text-centered">
                                Detalles:
                            </h3>

                            <table class="table is-fullwidth is-size-7">
                                <thead>
                                    <tr class="is-selected">
                                        <th>
                                            Tipo de transacción
                                        </th>

                                        <th>
                                            Concepto
                                        </th>

                                        <th>
                                            Monto pagado
                                        </th>

                                        <th>
                                            Monto recibido
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="has-text-centered">
                                        <td>
                                            {{ type == 'bank' ? 'Transferencia bancaria' : 'Retiro en efectivo' }}
                                        </td>

                                        <td>
                                            {{ transaction.concept }}
                                        </td>

                                        <td 
                                        class="has-text-weight-bold">
                                            {{ transaction.amount.toLocaleString('es-ES') }} €
                                        </td>

                                        <td v-if="type == 'bank'" 
                                        class="has-text-weight-bold">
                                            {{ transaction.bolivares.toLocaleString('es-ES') }} Bs
                                        </td>
                                        <td v-else 
                                        class="has-text-weight-bold">
                                            {{ transaction.bolivares.toLocaleString('es-ES') }} $
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <h3 class="subtitle mb-2 has-text-info-dark has-text-centered">
                                Beneficiario(s):
                            </h3>

                            <table class="table is-size-7 is-fullwidth"
                            v-if="type == 'bank'">
                                <thead>
                                    <tr class="has-background-primary-light">
                                        <th>
                                            Nombre y Apellidos:
                                        </th>

                                        <th>
                                            C.I
                                        </th>

                                        <th>
                                            Código de cuenta
                                        </th>

                                        <th>
                                            Importe
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in transaction.accounts"
                                    :key="`invoice-account-${item.id}`">
                                        <td>
                                            {{ item.beneficiary }}
                                        </td>

                                        <td>
                                            {{ item.dni }}
                                        </td>

                                        <td>
                                            {{ item.code }}
                                        </td>

                                        <td>
                                            <b>{{ (bolivar * item.pivot.amount).toLocaleString('es-ES') }} Bs</b>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>

                            <table v-else
                            class="table is-size-7 is-fullwidth">
                                <thead>
                                    <tr class="has-background-primary-light">
                                        <th>
                                            Nombre y Apellidos:
                                        </th>

                                        <th>
                                            C.I
                                        </th>

                                        <th>
                                            Teléfono
                                        </th>

                                        <th>
                                            Importe
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ transaction.cash.beneficiary }}
                                        </td>

                                        <td>
                                            {{ transaction.cash.dni }}
                                        </td>

                                        <td>
                                            {{ transaction.cash.phone }}
                                        </td>

                                        <td>
                                            <b>{{ transaction.amount }} $</b>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>

                    <div class="columns is-mobile mt-5">
                        <div class="column is-6 is-offset-6 has-text-centered"
                        style="border: 1px solid #CCC">
                            <h3 class="subtitle is-5">
                                Total:
                            </h3>
                            <h2 class="title is-4" 
                            v-if="type == 'bank'">
                                {{ transaction.bolivares }} Bs
                            </h2>
                            <h2 class="title is-4" 
                            v-else>
                                {{ transaction.amount }} $
                            </h2>
                        </div>
                    </div>

                </section>
            </div>
        </b-modal>

        <to-pdf :filename="transaction.reference"
        ref="pdfInvoice"
        pdf-format="a5"
        pdf-content-width="14.8cm"
        :pdf-quality="1.3"
        :manual-pagination="true"
        :html-a-pdf-opciones="{ enableLinks: true, margen: 52, html2canvas: { dpi: 300, letterRendering: true, useCORS: true }, image: { type: 'jpg', quality: .90 } }">

            <section slot="pdf-content"
            class="py-5 px-5">

                <div class="columns is-mobile">
                    <div class="column is-8">
                        <h3 class="subtitle has-text-weight-bold is-4 mb-3">
                            Cambiatuseuros, S.L.
                        </h3>
                        <ul class="is-size-7">
                            <li>
                                <b>CIF:</b> B88556501
                            </li>

                            <li>
                                <b>Teléfono:</b> +34 603 17 62 56
                            </li>

                            <li>
                                <b>E-mail:</b> info@cambiatuseuros.com
                            </li>

                        </ul>
                    </div>
                    <div class="column is-4">
                        <img :src="require('../assets/logo.png')"
                        style="max-width: 120px; display: block"
                        class="mx-auto">
                    </div>
                </div>
                
                <div class="line-divider my-3"></div>

                <div class="columns is-mobile px-3">
                    <div class="column is-6">
                        <h3 class="subtitle mb-2 has-text-info-dark">
                            Facturar a:
                        </h3>
                        <ul class="is-size-7">
                            <li>    
                                <b>Nombre:</b> {{ $root.user.name }} {{ $root.user.lastname }}
                            </li>

                            <li>    
                                <b>Documento N°:</b> {{ $root.user.dni }}
                            </li>

                            <li>
                                <b>Teléfono:</b> {{ $root.user.phone }} 
                            </li>

                            <li>
                                <b>Dirección:</b> {{ $root.user.direction }}, {{ $root.user.flat }}
                            </li>

                            <li>
                                <b>País de residencia:</b> {{ $root.user.residence }}
                            </li>

                            <li>
                                <b>Ciudad/Región:</b> {{ $root.user.city }}
                            </li>

                        </ul>
                    </div>

                    <div class="column is-6">
                        <div class="ml-auto is-pulled-right"
                        style="display: inline-block">
                            <p class="mb-0 is-size-7 has-text-weight-bold">
                                Número:
                            </p>
                            <h3 class="subtitle mb-2 is-4 has-text-info-dark has-text-weight-bold">
                                {{ transaction.reference }}
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="columns my-6">
                    <div class="column is-12">

                        <h3 class="subtitle mb-2 has-text-info-dark has-text-centered">
                            Detalles:
                        </h3>

                        <table class="table is-fullwidth is-size-7">
                            <thead>
                                <tr class="is-selected">
                                    <th>
                                        Tipo de transacción
                                    </th>

                                    <th>
                                        Concepto
                                    </th>

                                    <th>
                                        Monto pagado
                                    </th>

                                    <th>
                                        Monto recibido
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="has-text-centered">
                                    <td>
                                        {{ type == 'bank' ? 'Transferencia bancaria' : 'Retiro en efectivo' }}
                                    </td>

                                    <td>
                                        {{ transaction.concept }}
                                    </td>

                                    <td 
                                    class="has-text-weight-bold">
                                        {{ transaction.amount.toLocaleString('es-ES') }} €
                                    </td>

                                    <td v-if="type == 'bank'" 
                                    class="has-text-weight-bold">
                                        {{ transaction.bolivares.toLocaleString('es-ES') }} Bs
                                    </td>
                                    <td v-else 
                                    class="has-text-weight-bold">
                                        {{ transaction.bolivares.toLocaleString('es-ES') }} $
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <h3 class="subtitle mb-2 has-text-info-dark has-text-centered">
                            Beneficiario(s):
                        </h3>

                        <table class="table is-size-7 is-fullwidth"
                        v-if="type == 'bank'">
                            <thead>
                                <tr class="has-background-primary-light">
                                    <th>
                                        Nombre y Apellidos:
                                    </th>

                                    <th>
                                        C.I
                                    </th>

                                    <th>
                                        Código de cuenta
                                    </th>

                                    <th>
                                        Importe
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in transaction.accounts"
                                :key="`invoice-account-${item.id}`">
                                    <td>
                                        {{ item.beneficiary }}
                                    </td>

                                    <td>
                                        {{ item.dni }}
                                    </td>

                                    <td>
                                        {{ item.code }}
                                    </td>

                                    <td>
                                        <b>{{ (bolivar * item.pivot.amount).toLocaleString('es-ES') }} Bs</b>
                                    </td>

                                </tr>
                            </tbody>
                        </table>

                        <table v-else
                        class="table is-size-7 is-fullwidth">
                            <thead>
                                <tr class="has-background-primary-light">
                                    <th>
                                        Nombre y Apellidos:
                                    </th>

                                    <th>
                                        C.I
                                    </th>

                                    <th>
                                        Teléfono
                                    </th>

                                    <th>
                                        Importe
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ transaction.cash.beneficiary }}
                                    </td>

                                    <td>
                                        {{ transaction.cash.dni }}
                                    </td>

                                    <td>
                                        {{ transaction.cash.phone }}
                                    </td>

                                    <td>
                                        <b>{{ transaction.amount }} $</b>
                                    </td>

                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>

                <div class="columns is-mobile mt-6">
                    <div class="column is-6 is-offset-6 has-text-centered"
                    style="border: 1px solid #CCC">
                        <h3 class="subtitle is-5">
                            Total:
                        </h3>
                        <h2 class="title is-4" 
                        v-if="type == 'bank'">
                            {{ transaction.bolivares }} Bs
                        </h2>
                        <h2 class="title is-4" 
                        v-else>
                            {{ transaction.amount }} $
                        </h2>
                    </div>
                </div>

            </section>

        </to-pdf>

    </div>

    <div class="conatiner mt-4 px-4"
    v-else>

        <div class="columns">
            <div class="column">
                <b-skeleton animated
                height="1.5rem"
                width="40%"
                >
                </b-skeleton>
            </div>
        </div>

        <div class="columns">
            <div class="column is-8 is-offset-2">
                <div class="box">
                    <div class="mb-4">
                        <b-skeleton animated
                        width="90%"
                        height="1rem"
                        position="is-centered"
                        >
                        </b-skeleton>
                    </div>

                    <div class="mb-5">
                        <b-skeleton animated
                        :count="4"
                        width="70%"
                        position="is-centered"
                        >
                        </b-skeleton>
                    </div>
                    <div>
                        <b-skeleton animated
                        :count="4"
                        width="70%"
                        position="is-centered"
                        >
                        </b-skeleton>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import pdf from 'vue-pdf'
export default {
  watch: {
    $route(to){
        
        this.transaction = null
        this.images = []
        
        this.$http.get('api/transaction/' + to.params.reference, {
            params: {
                token: this.$root.auth.token
            }
        })
        .then(res => {

            this.transaction = res.data.transaction
            this.images = res.data.images

        })

    },
    onModal(){
        this.$parent.$parent.navbarZ()
    },
    onShowInvoice(){
        this.$parent.$parent.navbarZ()
    }
  },
  methods: {

    toCodeString(string){
        let vector = string.match(/.{1,4}/g);
        
        return vector.join(' '); 
    },

    showPreview(value, type){
        this.preview = value
        this.typePreview = type

        this.modal.preview = true
    },

    downloadFile(url, filename){
        this.$http.get(url, {
            responseType: 'blob'
        })
        .then(res => {
            let blob = new Blob([res.data])
            let uri = URL.createObjectURL(blob)
            let a = document.createElement('a')
            a.href = uri
            a.download = filename
            a.click()
        })
    }

  },
  mounted () {
      
      this.$http.get('api/transaction/' + this.$route.params.reference, {
          params: {
              token: this.$root.auth.token
          }
      })
      .then(res => {

          this.transaction = res.data.transaction
          this.images = res.data.images

      })
      .catch(error => {

          console.log(error.response)

      })

  },

  beforeMount () {
    if (! this.$root.isAuthenticate) {
      this.$router.push({name: 'Login'});
    }
  },
  
  data () {
    return {
        transaction: {},
        images: [],

        preview: null,
        typePreview: null,

        step: 0,
        modal: {
            preview: false,
            invoice: false
        }
    }
  },
  computed: {
    isReady(){
        return Object.keys(this.transaction).length > 0 ? true : false; 
    },
    type(){
        return this.transaction.accounts_count > 0 ? 'bank' : 'cash' 
    },
    bolivar(){
        return this.transaction.bolivares / this.transaction.amount
    },
    onModal(){
        return this.modal.preview
    },
    onShowInvoice(){
        return this.modal.invoice
    },
    typeImages(){
        return this.images.map( file => {

            return ['jpg', 'png', 'jpeg'].includes(file.extension) ? 'image' : 
            file.extension == 'pdf' ? 'pdf' :
            false

        } )
    }
  },
  components: {
      'to-pdf': VueHtml2pdf,
      pdf
  },
  name: 'Transaction'
}
</script>

<style lang="scss">
    .boxed-dashed{
        border: 2px dashed rgba(0,0,0, .3);
        border-radius: 5px;
    }
    figure.show-preview-transaction-credential{
        img{
            cursor: pointer !important;
            transition: .35s ease-in;
            position: relative !important;
        }
        span.icon{
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -1rem;
            margin-top: -1rem;
            cursor: pointer !important;
            opacity: 0;
            transition: .35s;
        }
        &:hover{
            img{
                filter: brightness(.4);
            }
            .icon{
                opacity: 1;
            }
        }
    }

    figure.show-preview-transaction-credentials-pdf{
        position: relative !important;

        span.icon{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 30;

        transform: translate(-50%, -50%);
        opacity: 0;
        
        transition: .35s ease-out;
        cursor: pointer;
        }

        &:hover span.icon{
        opacity: 1;
        }
        &:hover .background-pdf{
        opacity: 1;
        }

        .background-pdf{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
        transition: .35s ease-out;

        opacity: 0;
        background-color: rgba(0, 0, 0, .65);
        }
    }
</style>