<template>
    <b-button
        @click="initAmlbotKyc()"
        :disabled="sending"
        type="is-primary">
        Continuar
    </b-button>
</template>
<script>
export default {
    name: 'AmlbotKyc',

    props: {
        hasModified: Boolean,
        name: String,
        lastname: String
    },

    data() {
        return  {
            sending: false,
        }
    },

    methods: {
        async updateData() {
            return this.$http('api/user/verification', {
                method: 'put',
                headers: {
                    'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
                },
                data: {
                    name: this.name,
                    lastname: this.lastname
                }
            })
        },

        async initAmlbotKyc() {
            this.sending = true;

            if (this.hasModified) {
                await this.updateData()
                    .catch(
                        ({ response }) => {
                            if (response.status === 422) {
                                const errs = Object.values(response.data.errors)

                                errs.forEach(item => {
                                    this.$buefy.toast.open({
                                        type: 'is-danger',
                                        message: item[0],
                                        position: 'is-top-right',
                                        duration: 3000
                                    })
                                })
                            }
                        }
                    )
            }

            await this.$http(`/api/amlbotkyc/gatewayUrl`, {
                params: {
                    token: this.$root.auth.token
                },
                method: 'get',
            })
            .then(({data}) => {
                let url = data.form_url;
                //got to url
                if (url) {
                    window.location.href = url;
                }
            })
            .finally(() => {
                this.sending = false;
            })
        }
    }
}
</script>